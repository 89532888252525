import * as constant from "@/utils/consts";

export default {
  namespaced: true,
  state: {
    spaces: new Map([
      [constant.spacesEnum.SCENE, new Map()],
      [constant.spacesEnum.LOBBY, new Map()],
      ...constant.DEFAULT_TABLE_NAMES.map((el) => [el, new Map()]),
    ]),
  },
  mutations: {
    ADD_PEER_TO_SPACE(state, { spaceId, peerId, element }) {
      state.spaces.get(spaceId).set(peerId, element);
    },
    REMOVE_PEER_FROM_SPACE(state, { spaceId, peerId }) {
      state.spaces.get(spaceId).delete(peerId);
    },
    ADD_VIDEO_TO_PEER(state, { peer, mediaElement }) {
      peer.video = mediaElement;
    },
    ADD_AUDIO_TO_PEER(state, { peer, mediaElement }) {
      peer.audio = mediaElement;
    },
    REMOVE_VIDEO_FROM_PEER(state, { peer }) {
      peer.video = {};
    },
    REMOVE_AUDIO_FROM_PEER(state, { peer }) {
      peer.audio = {};
    },
    CLEAR_STATE(state) {
      state.spaces = new Map([
        [constant.spacesEnum.SCENE, new Map()],
        [constant.spacesEnum.LOBBY, new Map()],
        ...constant.DEFAULT_TABLE_NAMES.map((el) => [el, new Map()]),
      ]);
    },
  },
  actions: {
    addPeerToSpace({ commit }, { spaceId, peerId, element }) {
      commit("ADD_PEER_TO_SPACE", { spaceId, peerId, element });
    },
    removePeerFromSpace({ commit }, { spaceId, peerId }) {
      commit("REMOVE_PEER_FROM_SPACE", { spaceId, peerId });
    },
    addMediaToPeer({ commit, getters }, { mediaElement, spaceId, peerId }) {
      const peer = getters.getPeer({ spaceId, peerId });
      mediaElement.kind === constant.VIDEO
        ? commit("ADD_VIDEO_TO_PEER", { peer, mediaElement })
        : commit("ADD_AUDIO_TO_PEER", { peer, mediaElement });
    },
    removeMediaFromPeer({ getters, commit }, { spaceId, peerId, kind }) {
      const peer = getters.getPeer({ spaceId, peerId });
      if (!peer) {
        return;
      }
      kind === constant.VIDEO
        ? commit("REMOVE_VIDEO_FROM_PEER", { peer })
        : commit("REMOVE_AUDIO_FROM_PEER", { peer });
    },
    peerChangeSpace({ commit, getters }, { peerId, spaceId, oldSpaceId }) {
      const peer = getters.getPeer({ spaceId: oldSpaceId, peerId });
      if (!peer) {
        return;
      }
      commit("REMOVE_PEER_FROM_SPACE", { spaceId: oldSpaceId, peerId });
      commit("ADD_PEER_TO_SPACE", {
        spaceId,
        peerId,
        element: peer,
      });
    },
    clearState({ commit }) {
      commit("CLEAR_STATE");
    },
  },
  getters: {
    getPeer:
      (state) =>
      ({ spaceId, peerId }) => {
        return state.spaces.get(spaceId)?.get(peerId);
      },
    getUserAmount: (state) => {
      let counter = 0;
      for (const [, space] of state.spaces) {
        counter += space.size;
      }
      return counter;
    },
    peersCameraOnScene: (state, getters, rootState, rootGetters) => {
      let video = [];
      for (let [peerId, peer] of getters.getScene) {
        const isPaused = rootGetters["mediasoupWrapper/getMedia"]({
          id: peer.video.id,
          isProducer: peer.owner,
        })?.paused;
        if (isPaused !== undefined && !isPaused) {
          video.push({ peerId, video: peer.video, owner: peer.owner });
        }
      }
      return video;
    },
    getSpaces: (state) => {
      return state.spaces;
    },
    getSpace: (state) => (spaceId) => {
      return state.spaces.get(spaceId);
    },
    getScene: (state) => {
      return state.spaces.get(constant.spacesEnum.SCENE);
    },
    getLobby: (state) => {
      return state.spaces.get(constant.spacesEnum.LOBBY);
    },
  },
};
