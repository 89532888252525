import * as constant from "@/utils/consts";

export default {
  namespaced: true,
  actions: {
    async getRouterRtpCapabilities({ rootState }) {
      return await rootState.socket.socket.request(
        constant.GET_ROUTER_RTP_CAPABILITIES
      );
    },
    async createRoom({ rootState }, { roomId }) {
      await rootState.socket.socket
        .request(constant.CREATE_ROOM, { roomId })
        .catch((err) => {
          console.log(constant.CREATE_ROOM_ERROR, err);
        });
    },
    async joinRoom({ rootState }, { name, roomId, avatar, avatarColor }) {
      await rootState.socket.socket.request(constant.JOIN, {
        name,
        roomId,
        avatar,
        avatarColor,
      });
    },
    async createWebRtcTransport({ rootState }) {
      return await rootState.socket.socket.request(
        constant.CREATE_WEB_RTC_TRANSPORT
      );
    },
    connectTransport(
      { rootState },
      { transportId, dtlsParameters, callback, errback }
    ) {
      rootState.socket.socket
        .request(constant.CONNECT_TRANSPORT, {
          transportId,
          dtlsParameters,
        })
        .then(callback)
        .catch(errback);
    },
    async produceTransport(
      { rootState },
      { producerTransportId, kind, rtpParameters }
    ) {
      return await rootState.socket.socket.request(constant.PRODUCE, {
        producerTransportId,
        kind,
        rtpParameters,
      });
    },
    async consumeTransport(
      { rootState },
      { rtpCapabilities, consumerTransportId, producerId }
    ) {
      return await rootState.socket.socket.request(constant.CONSUME, {
        rtpCapabilities,
        consumerTransportId,
        producerId,
      });
    },
    async sendEmotion({ rootState }, { expressionObjectToSend }) {
      await rootState.socket.socket.request(
        constant.EMOTION,
        expressionObjectToSend
      );
    },
    exitRoom({ dispatch, rootState }, { offline }) {
      let clean = () => {
        rootState.mediasoupWrapper.mediasoupConsumer.consumerTransport.close();
        rootState.mediasoupWrapper.mediasoupProducer.producerTransport.close();
        rootState.socket.socket.off("disconnect");
        rootState.socket.socket.off("peerListInfo");
        rootState.socket.socket.off("newPeerInfo");
        rootState.socket.socket.off(constant.CLOSE_CONSUMER);
        dispatch("clearAllState", null, { root: true });
      };

      if (!offline) {
        rootState.socket.socket
          .request("exitRoom")
          .then((e) => console.log(e))
          .catch((e) => console.warn(e))
          .finally(() => {
            clean();
          });
      } else {
        clean();
      }
    },
  },
};
