import * as constant from "@/utils/consts";

export default {
  namespaced: true,
  state: {
    producers: new Map(),
    producerTransport: {},
    producerLabel: new Map(),
  },
  mutations: {
    CREATE_PRODUCER(state, { producer, type }) {
      state.producers.set(producer.id, producer);
      state.producerLabel.set(type, producer.id);
    },
    REMOVE_PRODUCER(state, { producer, type }) {
      state.producerLabel.delete(type);
      state.producers.delete(producer.id);
    },
    PAUSE_PRODUCER(state, { producerId }) {
      state.producers.get(producerId).pause();
    },
    RESUME_PRODUCER(state, { producerId }) {
      state.producers.get(producerId).resume();
    },
    REPLACE_PRODUCER_TRACK(state, { producerId, track }) {
      state.producers.get(producerId).replaceTrack({ track });
    },
    ADD_PRODUCER_TRANSPORT(state, { transport }) {
      state.producerTransport = transport;
    },
    CLEAR_STATE(state) {
      state.producerLabel = new Map();
      state.producers = new Map();
      state.producerTransport = {};
    },
  },
  actions: {
    async createProducer({ commit, dispatch, rootState }, context) {
      const { producer, mediaElement } = context.producerWithMedia;
      commit("CREATE_PRODUCER", {
        producer,
        type: context.type,
      });
      if (context.type === constant.VIDEO) {
        dispatch(
          "room/spaces/addMediaToPeer",
          {
            spaceId: rootState.user.spaceId,
            peerId: rootState.socket.socket.id,
            mediaElement,
          },
          { root: true }
        );
      }
    },
    async replaceProducerStreamTrack({ commit, dispatch, rootState }, context) {
      const { producerId, mediaElement } = context;
      const newTrack = mediaElement.srcObject.getVideoTracks()[0];

      commit("REPLACE_PRODUCER_TRACK", {
        producerId,
        track: newTrack,
      });
      dispatch(
        "room/spaces/addMediaToPeer",
        {
          spaceId: rootState.user.spaceId,
          peerId: rootState.socket.socket.id,
          mediaElement,
        },
        { root: true }
      );
    },
    async closeProducer({ commit, dispatch, rootState }, context) {
      const { producer, type } = context;
      commit("REMOVE_PRODUCER", {
        type,
        producer,
      });
      if (type === constant.VIDEO) {
        dispatch(
          "room/spaces/removeMediaFromPeer",
          {
            spaceId: rootState.user.spaceId,
            peerId: rootState.socket.socket.id,
            kind: type,
          },
          { root: true }
        );
      }
    },
    pauseProducer({ commit }, context) {
      commit("PAUSE_PRODUCER", context);
    },
    resumeProducer({ commit }, context) {
      commit("RESUME_PRODUCER", context);
    },
    addProducerTransport({ commit }, context) {
      commit("ADD_PRODUCER_TRANSPORT", context);
    },
    async removeConsumersAndInformProducers({ dispatch, rootGetters }) {
      const allAudioConsumers =
        rootGetters["mediasoupWrapper/mediasoupConsumer/getAllAudioConsumers"];
      const allAudioConsumersId = allAudioConsumers.map(
        (audioConsumer) => audioConsumer.id
      );

      for (let consumerId of allAudioConsumersId) {
        dispatch(
          "mediasoupWrapper/mediasoupConsumer/removeConsumer",
          { consumerId },
          { root: true }
        );
        dispatch(
          "socket/actionsEmit/closeConsumer",
          { consumerId },
          { root: true }
        );
      }
    },
    clearState({ commit }) {
      commit("CLEAR_STATE");
    },
  },
  getters: {
    isMediaOn: (state) => (type) => {
      return state.producerLabel.get(type);
    },
    getProducer: (state) => (type) => {
      const producer = state.producers.get(state.producerLabel.get(type));
      if (!producer) {
        console.log(constant.PRODUCER_NOT_FOUND_ERROR);
        return;
      }
      return producer;
    },
  },
};
