<template>
  <q-page>
    <div class="landing items-center column">
      <base-logo />
      <h1 class="landing__text text-h3 text-bold text-center">
        Online meetings that spark creativity
      </h1>
      <base-button
        data-cy="landing-join-meeting"
        class="landing__button q-mb-md"
        label="Join a Meeting"
        size="xl"
        theme="light"
        @click="joinMeeting"
      />
      <base-button
        data-cy="landing-new-meeting"
        class="landing__button q-mb-xl"
        label="New Meeting"
        size="xl"
        @click="newMeeting"
      />
      <p>
        <base-link
          :is-external="true"
          to="https://www.smalltalk.cafe/"
          class="q-mr-xs inline-block"
        >
          Learn more
        </base-link>
        <span>about SmallTalk.cafe</span>
      </p>
    </div>
  </q-page>
</template>

<script>
import BaseLogo from "@/components/BaseLogo";
import BaseButton from "@/components/BaseButton";
import BaseLink from "@/components/BaseLink";
import * as constant from "@/utils/consts";
export default {
  name: "LandingView",
  components: { BaseLink, BaseButton, BaseLogo },
  methods: {
    joinMeeting() {
      this.$router.push({ name: constant.JOIN });
    },
    newMeeting() {
      this.$router.push({ name: constant.MEETING });
    },
  },
};
</script>

<style lang="scss" scoped>
.landing {
  font-family: $font-primary;
  &__text {
    width: 17.9rem;
    height: 5.6rem;
    margin: 1.6rem 5.4rem 4.8rem 5.5rem;
    color: $white;
  }
  @include breakpoint.up(tablet) {
    @include center-element();
  }
}
</style>
