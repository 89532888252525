import { render } from "./RoomControlPanelSettings.vue?vue&type=template&id=494822ae&scoped=true"
import script from "./RoomControlPanelSettings.vue?vue&type=script&lang=js"
export * from "./RoomControlPanelSettings.vue?vue&type=script&lang=js"

import "./RoomControlPanelSettings.vue?vue&type=style&index=0&id=494822ae&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-494822ae"

export default script
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QIcon,QDialog,QCard,QCardSection,QSelect,QCardActions,QBtn});qInstall(script, 'directives', {ClosePopup});
