<template>
  <q-page>
    <base-loading v-if="loading" :connection-message="connectionMessage" />
    <meeting-room v-else />
  </q-page>
</template>

<script>
import BaseLoading from "@/components/BaseLoading.vue";
import MeetingRoom from "@/views/MeetingRoomView.vue";
import { mapState, mapActions, mapGetters } from "vuex";
import * as constant from "@/utils/consts";
import FaceExpressionMixin from "@/mixins/FaceExpressionMixin";
import ConsumerMixin from "@/mixins/ConsumerMixin";
import TransportMixin from "@/mixins/TransportMixin";

export default {
  name: "MediasoupConnection",

  components: {
    BaseLoading,
    MeetingRoom,
  },

  mixins: [FaceExpressionMixin, ConsumerMixin, TransportMixin],

  data() {
    return {
      routerRtpCapabilities: {},
      loading: true,
      connectionMessage: "",
    };
  },

  computed: {
    ...mapState({
      socket: (state) => state.socket.socket,

      userName: (state) => state.user.name,
      avatar: (state) => state.user.avatar,
      avatarColor: (state) => state.user.avatarColor,

      roomName: (state) => state.room.name,

      device: (state) => state.mediasoupWrapper.device,
    }),
    ...mapGetters({
      getPeer: "room/spaces/getPeer",
    }),
  },

  async mounted() {
    this.mapSocket();
    if (!this.socket.connected) {
      await this.$router.push({
        name: constant.JOIN,
        params: { name: this.$route.params["name"] },
      });
      return;
    }
    await this.setupStreamsAndModel();
    await this.createRoom();
    await this.joinRoom();
    await this.getRouterCapabilities();
    await this.loadDevice();
    await this.initTransports();
    this.getProducers({ isAudioOnly: false });
    this.loading = false;

    window.onpopstate = () => {
      this.exitRoom({ offline: false });
    };
    window.onunload = () => {
      this.exitRoom({ offline: false });
    };
  },

  sockets: {
    disconnectPeer({ peer, spaceId }) {
      this.removePeer({
        spaceId: spaceId,
        peerId: peer,
      });
    },
    peerListInfo(peerList) {
      peerList.map(async (peerInfo) => await this.updatePeer(peerInfo));
    },
    newPeerInfo(peerInfo) {
      this.updatePeer(peerInfo);
    },
    consumerClosed({ consumerId, peer: peerId, spaceId }) {
      // TODO: change peer to peerId in BE
      this.closeConsumer(consumerId, peerId, spaceId);
    },
    consumerPaused({ consumerId }) {
      this.pauseConsumer(consumerId);
    },
    consumerResumed({ consumerId }) {
      this.resumeConsumer(consumerId);
    },
    disconnect() {
      this.exitRoom({ offline: true });
    },
  },
  methods: {
    ...mapActions({
      setSocket: "socket/setSocket",
      getProducers: "socket/actionsEmit/getProducers",
      getRouterRtpCapabilities:
        "socket/actionsRequest/getRouterRtpCapabilities",
      socketCreateRoom: "socket/actionsRequest/createRoom",
      socketJoinRoom: "socket/actionsRequest/joinRoom",
      exitRoom: "socket/actionsRequest/exitRoom",

      setUserSpace: "user/setSpace",

      addPeer: "room/spaces/addPeerToSpace",
      removePeer: "room/spaces/removePeerFromSpace",

      createDevice: "mediasoupWrapper/createDevice",
      createStream: "media/ownStreams/createStream",
    }),
    async updatePeer(peer) {
      const { peerId, producers, spaceId } = peer;
      this.addPeerIfNotExists(peer);
      if (peerId === this.socket.id) {
        this.setUserSpace({ spaceId });
        return;
      }
      for (let producer of producers) {
        await this.createConsumer(producer, peerId, spaceId);
      }
    },
    addPeerIfNotExists({ peerId, peerName, spaceId, avatar, avatarColor }) {
      let peer = this.getPeer({
        spaceId,
        peerId,
      });
      if (peer) {
        return;
      }
      this.addPeer({
        spaceId,
        peerId,
        element: {
          peerName,
          avatar,
          avatarColor,
          owner: this.socket.id === peerId,
          video: {},
          audio: {},
        },
      });
    },
    setConnectionMessage(message) {
      this.connectionMessage = message;
    },
    mapSocket() {
      this.setConnectionMessage(constant.MESSAGE_MAP_SOCKET);
      this.setSocket({ socket: this.$socket.client });
    },
    async setupStreamsAndModel() {
      this.setConnectionMessage(constant.MESSAGE_LOAD_MODEL);
      await this.beforeInitEmotionRecognition();
      await this.createStream({ type: constant.VIDEO });
      await this.createStream({ type: constant.AUDIO });
      await this.initEmotionRecognition();
    },
    async createRoom() {
      this.setConnectionMessage(constant.MESSAGE_CREATE_ROOM);
      await this.socketCreateRoom({ roomId: this.roomName });
    },
    async joinRoom() {
      this.setConnectionMessage(constant.MESSAGE_JOIN_ROOM);
      await this.socketJoinRoom({
        name: this.userName,
        roomId: this.roomName,
        avatar: this.avatar,
        avatarColor: this.avatarColor,
      });
    },
    async getRouterCapabilities() {
      this.routerRtpCapabilities = await this.getRouterRtpCapabilities();
    },
    async loadDevice() {
      try {
        this.createDevice();
      } catch (error) {
        if (error.name === constant.UNSUPPORTED_ERROR) {
          console.error(constant.UNSUPPORTED_BROWSER_ERROR);
          alert(constant.UNSUPPORTED_BROWSER_ERROR);
        }
        console.error(error);
      }
      await this.device.load({
        routerRtpCapabilities: this.routerRtpCapabilities,
      });
    },
    async initTransports() {
      this.setConnectionMessage(constant.MESSAGE_INIT_TRANSPORTS);
      await this.createSendTransport();
      await this.createRecvTransport();
    },
  },
};
</script>

<style scoped></style>
