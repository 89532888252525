<template>
  <div
    :class="[`space__size--${size}`, 'space', 'relative-position', 'no-wrap']"
  >
    <base-space-name
      :name="spaceId"
      :size="isSceneAndNoVideoOnDesktop(spaceId) ? 'sm' : 'xs'"
    />
    <div
      :class="[
        'flex',
        `space__peers`,
        'q-gutter-sm',
        'absolute-center',
        'justify-center',
        { 'q-mt-lg': isTable(spaceId) },
      ]"
    >
      <room-spaces-peer
        v-for="peer in space"
        :key="peer[0]"
        :peer-id="peer[0]"
        :peer="peer[1]"
        :space-id="spaceId"
      />
    </div>
    <base-button
      :class="[
        'space__button',
        'full-height',
        'absolute-right',
        isSceneAndNoVideoOnDesktop(spaceId) ? 'text-h2' : 'text-h5',
      ]"
      label="Join"
      theme="light"
      :size="isSceneAndNoVideoOnDesktop(spaceId) ? 'sm' : 'xs'"
      @click="changeSpace"
    />
  </div>
</template>
<script>
import BaseSpaceName from "@/components/BaseSpaceName";
import BaseButton from "@/components/BaseButton";
import RoomSpacesPeer from "@/components/RoomSpacesPeer";
import { CHANGE_SPACE } from "@/utils/consts";
import ViewportMixin from "@/mixins/ViewportMixin";
import SpacesMixin from "@/mixins/SpacesMixin";

export default {
  name: "BaseSpace",

  components: { BaseSpaceName, RoomSpacesPeer, BaseButton },

  mixins: [ViewportMixin, SpacesMixin],

  props: {
    space: {
      type: Map,
      required: true,
    },
    spaceId: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: "xs",
      validator: (value) => {
        return ["xs", "sm"].includes(value);
      },
    },
  },

  emits: [CHANGE_SPACE],

  methods: {
    changeSpace() {
      this.$emit(CHANGE_SPACE, this.spaceId);
    },
  },
};
</script>

<style lang="scss" scoped>
.space {
  background-color: $grey-5;
  border-radius: $border-radius-md;
  &__button {
    border-radius: 0 $border-radius-md $border-radius-md 0;
  }
  &__peers {
    width: fit-content;
  }
  &__size {
    &--xs {
      min-width: 35.8rem;
      height: $standard-size-xs;
      width: min-content;
    }
    &--sm {
      min-width: 71.6rem;
      height: $standard-size-sm;
      width: min-content;
    }
  }
}
</style>
