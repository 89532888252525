<template>
  <q-layout view="hHh LpR fFf">
    <q-page-container>
      <router-view />
    </q-page-container>
  </q-layout>
  <dev-tools v-if="renderDevTools" class="fixed-bottom-right" />
</template>

<script>
import DevTools from "@/components/DevTools.vue";

export default {
  name: "CafeLayout",

  components: {
    DevTools,
  },

  data() {
    return {
      renderDevTools: process.env.NODE_ENV === "development",
    };
  },
};
</script>
