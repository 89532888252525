<template>
  <div class="column">
    <room-spaces-scene
      :class="{ 'q-mb-lg': !isMobilePortrait }"
      :scene="getScene"
      @change-space="changeSpace"
    />
    <div :class="['justify-center', isMobile ? 'column' : 'row', 'q-mt-xl']">
      <room-spaces-table
        v-for="[spaceId, space] of getSpacesWithoutScene"
        :key="spaceId"
        :space="space"
        :space-id="spaceId"
        @change-space="changeSpace(spaceId)"
      />
    </div>
    <room-spaces-lobby @change-space="changeSpace" />
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import RoomSpacesTable from "@/components/RoomSpacesTable";
import RoomSpacesScene from "@/components/RoomSpacesScene";
import * as constant from "@/utils/consts";
import RoomSpacesLobby from "@/components/RoomSpacesLobby";
import ViewportMixin from "@/mixins/ViewportMixin";

export default {
  name: "RoomSpaces",

  components: {
    RoomSpacesScene,
    RoomSpacesTable,
    RoomSpacesLobby,
  },

  sockets: {
    peerChangedSpace({ peerId, spaceId, oldSpaceId }) {
      this.peerChangeSpace({
        peerId,
        spaceId,
        oldSpaceId,
      });

      if (peerId === this.socket.id) {
        this.setUserSpace({ spaceId });
      }
    },
  },

  mixins: [ViewportMixin],

  emits: [constant.CHANGE_SPACE],

  computed: {
    ...mapState({
      socket: (state) => state.socket.socket,

      userSpaceId: (state) => state.user.spaceId,
    }),
    ...mapGetters({
      getScene: "room/spaces/getScene",
      getSpaces: "room/spaces/getSpaces",
    }),
    getSpacesWithoutScene() {
      const spaces = new Map([...this.getSpaces]);
      spaces.delete(constant.spacesEnum.SCENE);
      return spaces;
    },
  },

  methods: {
    ...mapActions({
      peerChangeSpace: "room/spaces/peerChangeSpace",

      setUserSpace: "user/setSpace",
    }),
    changeSpace(spaceId) {
      if (this.userSpaceId === spaceId) {
        return;
      }
      this.$emit(constant.CHANGE_SPACE, spaceId);
    },
  },
};
</script>

<style lang="scss" scoped></style>
