export default {
  data() {
    return {
      viewportWidth: window.innerWidth,
      viewportHeight: window.innerHeight,
    };
  },

  mounted() {
    window.addEventListener("resize", () => {
      this.viewportWidth = window.innerWidth;
      this.viewportHeight = window.innerHeight;
    });
  },

  computed: {
    isMobilePortrait() {
      return (
        this.viewportWidth <= 480 && this.viewportWidth < this.viewportHeight
      );
    },
    isMobileLandscape() {
      return (
        this.viewportHeight <= 480 && this.viewportWidth > this.viewportHeight
      );
    },
    isMobile() {
      return this.isMobilePortrait || this.isMobileLandscape;
    },
  },
};
