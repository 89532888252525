import { render } from "./RoomSpacesLobby.vue?vue&type=template&id=1c93aadb&scoped=true"
import script from "./RoomSpacesLobby.vue?vue&type=script&lang=js"
export * from "./RoomSpacesLobby.vue?vue&type=script&lang=js"

import "./RoomSpacesLobby.vue?vue&type=style&index=0&id=1c93aadb&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-1c93aadb"

export default script
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QDialog,QCard,QCardSection});
