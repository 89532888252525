<template>
  <div :style="getAvatar" :class="['avatar', `avatar--${size}`]">
    <slot />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { AVATAR_COLORS as COLORS } from "@/utils/consts";

export default {
  name: "BaseAvatar",

  props: {
    peer: {
      type: Object,
      default: null,
    },
    size: {
      type: String,
      default: "md",
      validator: (value) => {
        return ["md", "lg"].includes(value);
      },
    },
  },
  computed: {
    ...mapGetters({
      getUserAvatarColor: "user/getAvatarColor",
      getUserAvatar: "user/getAvatar",
    }),
    getAvatar() {
      const avatar = this.peer ? this.peer.avatar : this.getUserAvatar;
      const color = this.peer ? this.peer.avatarColor : this.getUserAvatarColor;
      return {
        "background-image": `url(${avatar ?? require("@/assets/avatar.svg")})`,
        "background-color": color,
      };
    },
  },
  mounted() {
    if (this.getUserAvatarColor) {
      return;
    }
    this.createAvatarColor();
  },
  methods: {
    ...mapActions({
      setAvatarColor: "user/setAvatarColor",
    }),
    createAvatarColor() {
      const color = COLORS[Math.floor(Math.random() * COLORS.length)];
      this.setAvatarColor({ color });
    },
  },
};
</script>

<style lang="scss" scoped>
.avatar {
  background-position: center;
  background-origin: border-box;
  background-size: cover;
  &--md {
    @include circle-size($standard-size-xs);
  }
  &--lg {
    @include circle-size($standard-size-sm);
  }
}
</style>
