import { render } from "./RoomControlPanel.vue?vue&type=template&id=2ffa8ce8&scoped=true"
import script from "./RoomControlPanel.vue?vue&type=script&lang=js"
export * from "./RoomControlPanel.vue?vue&type=script&lang=js"

import "./RoomControlPanel.vue?vue&type=style&index=0&id=2ffa8ce8&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-2ffa8ce8"

export default script
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QBadge from 'quasar/src/components/badge/QBadge.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QIcon,QBadge});
