import { render } from "./RoomControlPanelBarometerEmotionIcon.vue?vue&type=template&id=4088f27e&scoped=true"
import script from "./RoomControlPanelBarometerEmotionIcon.vue?vue&type=script&lang=js"
export * from "./RoomControlPanelBarometerEmotionIcon.vue?vue&type=script&lang=js"

import "./RoomControlPanelBarometerEmotionIcon.vue?vue&type=style&index=0&id=4088f27e&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-4088f27e"

export default script
import QIcon from 'quasar/src/components/icon/QIcon.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QIcon});
