import { render } from "./RoomSpacesSceneChat.vue?vue&type=template&id=78514b48&scoped=true"
import script from "./RoomSpacesSceneChat.vue?vue&type=script&lang=js"
export * from "./RoomSpacesSceneChat.vue?vue&type=script&lang=js"

import "./RoomSpacesSceneChat.vue?vue&type=style&index=0&id=78514b48&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-78514b48"

export default script
import QScrollArea from 'quasar/src/components/scroll-area/QScrollArea.js';
import QChatMessage from 'quasar/src/components/chat/QChatMessage.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QInput from 'quasar/src/components/input/QInput.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QScrollArea,QChatMessage,QBtn,QInput});
