<template>
  <div>
    <span class="q-ma-none text-h2 text-bold" data-cy="room-info-room-name">
      {{ roomName }}
    </span>
    <div class="flex q-gutter-sm no-wrap">
      <q-icon name="groups" class="text-h2 column inline" />
      <span class="text-h3 text-bold" data-cy="room-info-user-amount">
        {{ getUserAmount }}
      </span>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
  name: "TheRoomInfo",

  computed: {
    ...mapState({
      roomName: (state) => state.room.name,
    }),
    ...mapGetters({
      getUserAmount: "room/spaces/getUserAmount",
    }),
  },
};
</script>

<style lang="scss" scoped>
// Style tag must be added in order to load global sass styles
// Don't delete!
</style>
