import * as constant from "@/utils/consts";

export default {
  namespaced: true,
  actions: {
    getProducers({ rootState }, { isAudioOnly }) {
      rootState.socket.socket.emit(constant.GET_PRODUCERS, {
        isAudioOnly,
      });
    },
    changeSpace({ rootState }, { spaceId, oldSpaceId }) {
      rootState.socket.socket.emit(constant.CHANGE_SPACE, {
        spaceId,
        oldSpaceId,
      });
    },
    sendChatMessage({ rootState }, { text, authorId, currentDate }) {
      rootState.socket.socket.emit(constant.SEND_CHAT_MESSAGE, {
        text,
        authorId,
        stamp: currentDate.toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        }),
      });
    },
    closeProducer({ rootState }, { producerId }) {
      console.log("Close producer", producerId);
      rootState.socket.socket.emit(constant.CLOSE_PRODUCER, { producerId });
    },
    pauseProducer({ rootState }, { producerId }) {
      console.log("Pause producer", producerId);
      rootState.socket.socket.emit(constant.PAUSE_PRODUCER, { producerId });
    },
    resumeProducer({ rootState }, { producerId }) {
      console.log("Resume producer", producerId);
      rootState.socket.socket.emit(constant.RESUME_PRODUCER, { producerId });
    },
    consumerCreated({ rootState }, { consumerId }) {
      rootState.socket.socket.emit(constant.CONSUMER_CREATED, { consumerId });
    },
    closeConsumer({ rootState }, { consumerId }) {
      rootState.socket.socket.emit(constant.CLOSE_CONSUMER, {
        consumerId,
      });
    },
  },
};
