import * as constant from "@/utils/consts";
import { mapGetters } from "vuex";
import ViewportMixin from "@/mixins/ViewportMixin";

export default {
  mixins: [ViewportMixin],

  computed: {
    ...mapGetters({
      peersCameraOnScene: "room/spaces/peersCameraOnScene",
    }),
  },

  methods: {
    isScene(spaceId) {
      return spaceId === constant.spacesEnum.SCENE;
    },
    isLobby(spaceId) {
      return spaceId === constant.spacesEnum.LOBBY;
    },
    isTable(spaceId) {
      return !this.isScene(spaceId) && !this.isLobby(spaceId);
    },
    isSceneAndNoVideoOnDesktop(spaceId) {
      return (
        this.isScene(spaceId) &&
        this.peersCameraOnScene.length === 0 &&
        !this.isMobile
      );
    },
  },
};
