<template>
  <div>
    <label :for="name" class="text-p"><slot /></label>
    <q-input
      :for="name"
      :model-value="modelValue"
      :class="[
        { 'input--read-only': readOnly },
        'input',
        'q-mt-sm',
        'q-pa-none',
      ]"
      color="accent"
      dense
      outlined
      :readonly="readOnly"
      :placeholder="placeholder"
      :rules="rules"
      @change="onChange"
    />
  </div>
</template>

<script>
import { UPDATE_MODEL_VALUE } from "@/utils/consts";

export default {
  name: "BaseInput",

  props: {
    modelValue: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
    rules: {
      type: Array,
      default: () => [],
    },
  },

  emits: [UPDATE_MODEL_VALUE],

  methods: {
    onChange(e) {
      this.$emit(UPDATE_MODEL_VALUE, e);
    },
  },
};
</script>
<style lang="scss" scoped>
.input {
  width: $standard-width;
  &--read-only {
    background-color: $grey-5;
  }
  ::v-deep .q-field {
    &__native {
      color: $white-2;
    }
    &__control {
      &:hover:before {
        border: solid 0.2rem $violet-2 !important;
      }
      &:before {
        border: solid 0.1rem $grey-7 !important;
      }
    }
  }
}
</style>
