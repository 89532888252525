import { render } from "./TheRoomInfo.vue?vue&type=template&id=955a160c&scoped=true"
import script from "./TheRoomInfo.vue?vue&type=script&lang=js"
export * from "./TheRoomInfo.vue?vue&type=script&lang=js"

import "./TheRoomInfo.vue?vue&type=style&index=0&id=955a160c&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-955a160c"

export default script
import QIcon from 'quasar/src/components/icon/QIcon.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QIcon});
