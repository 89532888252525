export const videoEncodings = [
  {
    rid: "r0",
    maxBitrate: 100000,
    //scaleResolutionDownBy: 10.0,
    scalabilityMode: "S1T3",
  },
  {
    rid: "r1",
    maxBitrate: 300000,
    scalabilityMode: "S1T3",
  },
  {
    rid: "r2",
    maxBitrate: 900000,
    scalabilityMode: "S1T3",
  },
];

export const videoCodecOptions = {
  videoGoogleStartBitrate: 1000,
};

export const videoMediaConstraintsMobileiOS = {
  audio: false,
  video: {
    width: 600,
    height: 600,
  },
};

export const videoMediaConstraints = {
  audio: false,
  video: {
    width: {
      min: 640,
      ideal: 1920,
    },
    height: {
      min: 400,
      ideal: 1080,
    },
    /*aspectRatio: {
          ideal: 1.7777777778
      }*/
  },
};

export const audioMediaConstraints = {
  video: false,
};
