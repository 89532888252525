import * as mediasoupClient from "mediasoup-client";
import mediasoupConsumer from "@/store/modules/mediasoupWrapper/mediasoupConsumer";
import mediasoupProducer from "@/store/modules/mediasoupWrapper/mediasoupProducer";

export default {
  namespaced: true,
  modules: {
    mediasoupConsumer,
    mediasoupProducer,
  },
  state: {
    device: {},
  },
  mutations: {
    CREATE_DEVICE(state, { device }) {
      state.device = device;
    },
    CLEAR_STATE(state) {
      state.device = {};
    },
  },
  actions: {
    createDevice({ commit }) {
      let device = new mediasoupClient.Device();
      commit("CREATE_DEVICE", { device });
    },

    clearState({ commit, dispatch }) {
      dispatch("mediasoupConsumer/clearState");
      dispatch("mediasoupProducer/clearState");
      commit("CLEAR_STATE");
    },
  },
  getters: {
    getDevice: (state) => {
      return state.device;
    },
    getMedia:
      (state) =>
      ({ id, isProducer }) => {
        return isProducer
          ? state.mediasoupProducer.producers.get(id)
          : state.mediasoupConsumer.consumers.get(id);
      },
  },
};
