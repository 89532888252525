import { createStore } from "vuex";

import socket from "./modules/socket";
import user from "./modules/user";
import room from "./modules/room";
import media from "./modules/media";
import mediasoupWrapper from "./modules/mediasoupWrapper";
import chat from "./modules/chat";

export default createStore({
  modules: {
    socket,
    user,
    room,
    media,
    mediasoupWrapper,
    chat,
  },
  actions: {
    clearAllState({ dispatch }) {
      dispatch("user/clearState");
      dispatch("room/clearState");
      dispatch("mediasoupWrapper/clearState");
      dispatch("media/clearState");
    },
  },
});
