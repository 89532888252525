export default {
  namespaced: true,
  state: {
    name: "",
    spaceId: 0,
    avatar: null,
    avatarColor: null,
  },
  mutations: {
    SET_USERNAME(state, { userName }) {
      state.name = userName;
    },
    SET_SPACE(state, { spaceId }) {
      state.spaceId = spaceId;
    },
    SET_AVATAR(state, { avatar }) {
      state.avatar = avatar;
    },
    SET_AVATAR_COLOR(state, { color }) {
      state.avatarColor = color;
    },
    CLEAR_STATE(state) {
      state.socket = {};
      state.name = "";
      state.spaceId = 0;
      state.avatar = null;
    },
  },
  actions: {
    setName({ commit }, context) {
      commit("SET_USERNAME", context);
    },
    setSpace({ commit }, context) {
      commit("SET_SPACE", context);
    },
    setAvatar({ commit }, context) {
      commit("SET_AVATAR", context);
    },
    setAvatarColor({ commit }, context) {
      commit("SET_AVATAR_COLOR", context);
    },
    clearState({ commit }) {
      commit("CLEAR_STATE");
    },
  },
  getters: {
    getAvatarColor: (state) => {
      return state.avatarColor;
    },
    getAvatar: (state) => {
      return state.avatar;
    },
    isOwnMessage: (state) => (authorId) => {
      return authorId === state.name;
    },
    isUserSpace: (state) => (spaceId) => {
      return spaceId === state.spaceId;
    },
  },
};
