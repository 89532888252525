<template>
  <div class="flex chat absolute full-height" @click.stop="">
    <q-scroll-area
      ref="chatScroll"
      class="full-width chat__scroll rounded-borders"
    >
      <div
        v-for="message in chatMessages"
        :key="message.key"
        class="chat__message"
      >
        <q-chat-message
          v-if="isOwnMessage(message.authorId)"
          :text="[message.text]"
          :stamp="message.stamp"
          text-color="white"
          sent
        />
        <q-chat-message
          v-else-if="isAnotherAuthor(message)"
          :text="[message.text]"
          :name="message.authorId"
          :stamp="message.stamp"
          bg-color="grey-10"
          text-color="white"
        />
        <p v-else>
          {{ message.text }}
        </p>
      </div>
    </q-scroll-area>
    <q-btn
      v-if="isScrolled"
      round
      icon="expand_more"
      color="grey-10"
      class="justify-center q-mx-auto chat__scroll-button"
      @click="scrollToBottom"
    />
    <q-input
      v-model="newMessage"
      rounded
      outlined
      dense
      :class="[
        { 'absolute chat__input--scroll': isScrolled },
        'full-width',
        'chat__input',
      ]"
      bg-color="grey-9"
      label="New message"
      @keyup.enter="sendTextMessage"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  name: "RoomChat",

  data() {
    return {
      newMessage: "",
      chatUpdated: false,
    };
  },

  computed: {
    ...mapState({
      userName: (state) => state.user.name,
      chatMessages: (state) => state.chat.chatMessages,
      isChatOn: (state) => state.chat.isChatOn,
    }),
    ...mapGetters({
      isOwnMessage: "user/isOwnMessage",
    }),
    isScrolled() {
      if (this.chatMessages.length === 0) {
        return false;
      }
      const scrollArea = this.$refs.chatScroll;
      let scrollPercentage = scrollArea.getScrollPercentage().top;
      let scroll = scrollArea.getScroll();
      return (
        scrollPercentage !== 1 &&
        scroll.verticalContainerSize < scroll.verticalSize
      );
    },
  },
  watch: {
    isChatOn(newValue) {
      if (newValue) {
        this.chatUpdated = true;
      }
    },
  },

  updated() {
    if (this.chatUpdated) {
      this.scrollToBottom();
      this.chatUpdated = false;
    }
  },
  sockets: {
    chatMessage(data) {
      if (this.isOwnMessage(data.authorId) || !this.isScrolled) {
        this.chatUpdated = true;
      }
      this.addMessage(data);
      if (!this.isOwnMessage(data.authorId)) {
        this.incrementNewMessagesQuantity();
      }
    },
  },

  methods: {
    ...mapActions({
      sendChatMessage: "socket/actionsEmit/sendChatMessage",

      addChatMessage: "chat/addChatMessage",
      toggleChat: "chat/toggleChat",

      incrementNewMessagesQuantity: "chat/incrementNewMessagesQuantity",
    }),
    addMessage({ text, authorId, stamp }) {
      this.addChatMessage({
        key: this.chatMessages.length,
        text,
        authorId,
        stamp,
      });
    },
    isAnotherAuthor(message) {
      return message.authorId !== this.userName && message.authorId !== null;
    },
    sendTextMessage() {
      if (this.newMessage !== "") {
        let currentDate = new Date();
        this.sendChatMessage({
          text: this.newMessage,
          authorId: this.userName,
          currentDate,
        });
        this.newMessage = "";
      }
    },
    scrollToBottom() {
      const scrollArea = this.$refs.chatScroll;
      const scrollTarget = scrollArea.getScrollTarget();
      const duration = 0;
      scrollArea.setScrollPosition(
        "vertical",
        scrollTarget.scrollHeight + 1000,
        duration
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.chat {
  background-color: $black-3;
  width: 24%;
  border-radius: 0 $border-radius-lg $border-radius-lg 0;
  opacity: 0.8;
  direction: ltr;
  &__scroll {
    border-radius: 0 $border-radius-lg 0 0;
    background-color: $grey-9;
    height: 87%;
  }
  &__scroll-button {
    z-index: 10;
    bottom: 5rem;
    height: 2.5rem;
  }
  &__message {
    margin: 0.5rem 1rem;
  }
  &__input {
    bottom: 1.5rem;
    height: 4rem;
    padding: 0 1rem;
    &--scroll {
      padding: 0.5rem 1rem;
    }
  }
}
::v-deep .q-message-text--sent {
  background: linear-gradient(to right, $pink, $orange);
  color: $orange !important;
}
</style>
