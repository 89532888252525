<template>
  <q-form class="form column q-mx-auto q-mt-lg" @submit="onSubmit">
    <base-link-back />
    <h1 class="text-bold q-mt-md q-mb-xl">New meeting</h1>
    <base-input
      v-model="meetingName"
      data-cy="meeting-name-button"
      class="q-mb-lg"
      :rules="getNameMeetingRules"
      name="meeting-name"
    >
      Meeting name
    </base-input>
    <base-input
      class="q-mb-xl"
      :placeholder="createLink"
      read-only
      name="meeting-link"
    >
      Meeting link
    </base-input>
    <div class="flex justify-between">
      <base-button v-if="isCopyToClipboard" theme="light" size="md">
        <q-icon name="done" size="sm" />
        <span class="q-ml-sm">Copied</span>
      </base-button>
      <base-button
        v-else
        size="md"
        theme="light"
        type="submit"
        label="Copy link"
      />
      <base-button label="Join now" size="md" @click="joinMeeting" />
    </div>
  </q-form>
</template>

<script>
import { mapActions } from "vuex";
import BaseLinkBack from "@/components/BaseLinkBack";
import BaseInput from "@/components/BaseInput";
import BaseButton from "@/components/BaseButton";
import { JOIN } from "@/utils/consts";

export default {
  name: "MeetingNewView",
  components: { BaseButton, BaseInput, BaseLinkBack },
  data() {
    return {
      meetingName: "",
      isCopyToClipboard: false,
    };
  },

  computed: {
    createLink() {
      let beginLink = `${window.location.protocol}//${window.location.hostname}`;
      if (window.location.port && window.location.port != "") {
        beginLink = `${beginLink}:${window.location.port}`;
      }
      return `${beginLink}/join/${this.replaceSpace(this.meetingName)}`;
    },
    getNameMeetingRules() {
      return [
        (meetingName) =>
          (meetingName && meetingName.length > 0) ||
          "Please specify meeting name",
        (meetingName) => meetingName.indexOf("/") < 0 || "Slash is not allowed",
      ];
    },
  },

  methods: {
    ...mapActions({
      setRoomName: "room/setName",
    }),
    onSubmit() {
      navigator.clipboard.writeText(this.createLink);
      this.isCopyToClipboard = true;
      return this.isCopyToClipboard;
    },

    joinMeeting() {
      this.setRoomName({ roomName: this.meetingName });
      this.$router.push({ name: JOIN, params: { name: this.meetingName } });
    },
    replaceSpace(value) {
      if (value.indexOf(" ") >= 0) {
        for (let i = 0; i < value.length; i++) {
          value = value.replace(" ", "%20");
        }
      }
      return value;
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  font-family: $font-primary;
  width: $standard-width;
  @include breakpoint.up(tablet) {
    @include center-element();
  }
}
</style>
