import * as constant from "@/utils/consts";
import * as config from "@/utils/stream-config";

export default {
  namespaced: true,
  state: {
    video: {},
    screen: {},
    audio: {},
  },
  mutations: {
    SET_OWN_STREAM(state, { type, stream, params }) {
      state[type] = { stream, params };
    },
    CLOSE_OWN_STREAM(state, { type }) {
      state[type] = {};
    },
    CLEAR_STATE(state) {
      state.video = {};
      state.screen = {};
      state.audio = {};
    },
  },
  actions: {
    async createStream({ commit, rootState }, context) {
      const { type } = context;

      let mediaConfig;
      let mobileiOS =
        /iPad|iPhone|iPod/.test(navigator.platform) ||
        (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1);

      switch (type) {
        case constant.AUDIO:
          mediaConfig = config.audioMediaConstraints;
          mediaConfig = {
            ...mediaConfig,
            audio: {
              deviceId:
                rootState.media.mediaDevices.audioInput.value ?? "default",
            },
          };
          break;
        case constant.VIDEO:
        case constant.SCREEN_SHARE:
          mediaConfig = mobileiOS
            ? config.videoMediaConstraintsMobileiOS
            : config.videoMediaConstraints;
          mediaConfig = {
            ...mediaConfig,
            video: {
              ...mediaConfig.video,
              deviceId:
                rootState.media.mediaDevices.videoInput.value ?? "default",
            },
          };
          break;
        default:
          console.log("Unsupported stream type");
      }
      try {
        let stream =
          type === constant.SCREEN_SHARE
            ? await navigator.mediaDevices.getDisplayMedia()
            : await navigator.mediaDevices.getUserMedia(mediaConfig);
        console.log(navigator.mediaDevices.getSupportedConstraints());

        const track =
          type === constant.AUDIO
            ? stream.getAudioTracks()[0]
            : stream.getVideoTracks()[0];
        const params = {
          track,
        };

        if (type === constant.VIDEO) {
          params.encodings = config.videoEncodings;
          params.codecOptions = config.videoCodecOptions;
          params.disableTrackOnPause = false;
          params.zeroRtpOnPause = true;
        }

        params.isScreen = type === constant.SCREEN_SHARE;
        commit("SET_OWN_STREAM", { type, stream, params });
        return stream;
      } catch (e) {
        console.log(e);
      }
    },
    closeOwnStream({ commit, getters }, context) {
      const { type } = context;
      const stream = getters.getStream({ type });
      if (!stream) {
        return;
      }
      stream.getTracks().forEach((track) => {
        track.stop();
      });
      commit("CLOSE_OWN_STREAM", context);
    },
    closeOwnStreams({ commit, state }) {
      Object.values(state).forEach((value) => {
        let stream = value.stream;
        stream?.getTracks().forEach((track) => {
          track.stop();
        });
      });
      commit("CLOSE_OWN_STREAM", { type: constant.AUDIO });
      commit("CLOSE_OWN_STREAM", { type: constant.VIDEO });
    },
    async recreateStream({ dispatch }, context) {
      const { type } = context;
      dispatch("closeOwnStream", { type });
      await dispatch("createStream", { type });
    },
    clearState({ commit }) {
      commit("CLEAR_STATE");
    },
  },
  getters: {
    getStream:
      (state) =>
      ({ type }) => {
        return state[type].stream;
      },
  },
};
