import spaces from "@/store/modules/room/spaces";

export default {
  namespaced: true,
  modules: {
    spaces,
  },
  state: {
    name: "",
    isLobbyMode: false,
    emotionsIntervalId: null,
  },
  mutations: {
    SET_NAME(state, { roomName }) {
      state.name = roomName;
    },
    TOGGLE_LOBBY_MODE(state) {
      state.isLobbyMode = !state.isLobbyMode;
    },
    SET_EMOTIONS_INTERVAL_ID(state, { intervalId }) {
      state.emotionsIntervalId = intervalId;
    },
    CLEAR_EMOTIONS_INTERVAL_ID(state) {
      state.emotionsIntervalId = null;
    },
    CLEAR_STATE(state) {
      state.name = "";
      state.isLobbyMode = false;
    },
  },
  actions: {
    setName({ commit }, context) {
      commit("SET_NAME", context);
    },
    setEmotionsIntervalId({ commit, state }, { intervalId }) {
      if (state.emotionsIntervalId) {
        clearInterval(state.emotionsIntervalId);
      }
      setInterval(intervalId);
      commit("SET_EMOTIONS_INTERVAL_ID", { intervalId });
    },
    clearEmotionsIntervalId({ commit, state }) {
      clearInterval(state.emotionsIntervalId);
      commit("CLEAR_EMOTIONS_INTERVAL_ID");
    },
    toggleLobbyMode({ commit }) {
      commit("TOGGLE_LOBBY_MODE");
    },
    clearState({ commit }) {
      commit("spaces/CLEAR_STATE");
      commit("CLEAR_STATE");
    },
  },
};
