import { mapActions, mapGetters } from "vuex";
import { VIDEO } from "@/utils/consts";

export default {
  computed: {
    ...mapGetters({
      getStream: "media/ownStreams/getStream",
    }),
  },
  methods: {
    ...mapActions({
      createStream: "media/ownStreams/createStream",
    }),
    async takePhoto() {
      await this.createStream({ type: VIDEO });
      const stream = this.getStream({ type: VIDEO });
      const blob = await this.getBlobFromMediaStream(stream);
      const reader = new FileReader();
      reader.readAsDataURL(blob);

      return new Promise((resolve, reject) => {
        reader.onload = (event) => {
          const imgElement = document.createElement("img");
          imgElement.src = event.target.result;

          imgElement.onload = (e) => {
            const canvas = document.createElement("canvas");
            const MAX_WIDTH = 200;

            const scaleSize = MAX_WIDTH / e.target.width;
            canvas.width = MAX_WIDTH;
            canvas.height = e.target.height * scaleSize;

            const ctx = canvas.getContext("2d");

            ctx.drawImage(e.target, 0, 0, canvas.width, canvas.height);

            const srcEncoded = ctx.canvas.toDataURL(e.target, "image/jpeg");
            resolve(srcEncoded);
          };
          imgElement.onerror = reject;
        };
      });
    },
    getBlobFromMediaStream(stream) {
      if ("ImageCapture" in window) {
        const videoTrack = stream.getVideoTracks()[0];
        const imageCapture = new ImageCapture(videoTrack);
        return imageCapture.takePhoto();
      } else {
        const video = document.createElement("video");
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");

        video.srcObject = stream;

        return new Promise((resolve, reject) => {
          video.addEventListener("loadeddata", async () => {
            const { videoWidth, videoHeight } = video;
            canvas.width = videoWidth;
            canvas.height = videoHeight;

            try {
              await video.play();
              context.drawImage(video, 0, 0, videoWidth, videoHeight);
              canvas.toBlob(resolve, "image/png");
            } catch (error) {
              reject(error);
            }
          });
        });
      }
    },
  },
};
