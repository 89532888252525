import * as constant from "@/utils/consts";
import { mapState, mapActions } from "vuex";

export default {
  computed: {
    ...mapState({
      video: (state) => state.media.ownStreams.video,
      audio: (state) => state.media.ownStreams.audio,

      device: (state) => state.mediasoupWrapper.device,
      producerTransport: (state) =>
        state.mediasoupWrapper.mediasoupProducer.producerTransport,
      producerLabel: (state) =>
        state.mediasoupWrapper.mediasoupProducer.producerLabel,
    }),
  },
  methods: {
    ...mapActions({
      closeOwnStream: "media/ownStreams/closeOwnStream",
      closeProducerSocket: "socket/actionsEmit/closeProducer",
      closeProducerStore: "mediasoupWrapper/mediasoupProducer/closeProducer",
    }),
    _canCreateProducer(type) {
      if (!this.device.canProduce(type)) {
        console.error(`${constant.PRODUCER_CREATE_ERROR} ${type}`);
        return false;
      }
      if (this.producerLabel.has(type)) {
        console.log(`${constant.PRODUCER_EXISTS_ERROR} ${type}`);
        return false;
      }
      return true;
    },
    async _createProducerWithMedia(type) {
      const { stream, params } =
        type === constant.VIDEO ? this.video : this.audio;
      const producer = await this.producerTransport.produce(params);
      await this._createProducerEventListeners(producer);
      return {
        producer,
        mediaElement: {
          id: producer.id,
          kind: type,
          srcObject: stream,
          isScreen: params.isScreen,
        },
      };
    },
    async _createProducerEventListeners(producer) {
      const producerId = producer.id;
      const type = producer.kind;
      const logInfo = `Type: ${type}, ProducerId: ${producerId}`;

      producer.on(constant.TRANSPORT_CLOSE, async () => {
        console.log(`${constant.MESSAGE_TRANSPORT_CLOSED}: ${logInfo}`);
        await this.closeOwnStream({ type });
      });

      producer.on(constant.TRACK_ENDED, async () => {
        console.log(`${constant.MESSAGE_TRACK_ENDED}: ${logInfo}`);
        await this.closeOwnStream({ type });
        this.closeProducerSocket({ producerId });
        await this.closeProducerStore({ type, producer });
      });
    },
  },
};
