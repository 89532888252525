<template>
  <div v-for="emotion in emotions" :key="emotion">
    <room-control-panel-barometer-emotion-icon
      :emotion-bar-length="emotionBarLength"
      :color="emotionsColors[emotion]"
      :emotion-name="emotion"
      :emotion-score="emotionsScore[emotion]"
    />
  </div>
</template>

<script>
import RoomControlPanelBarometerEmotionIcon from "@/components/RoomControlPanelBarometerEmotionIcon";
import { EMOTIONS_COLORS } from "@/utils/consts";
export default {
  name: "RoomControlPanelBarometerEmotion",

  components: { RoomControlPanelBarometerEmotionIcon },

  data() {
    return {
      emotionsCount: {
        happy: 0,
        sad: 0,
        surprised: 0,
        angry: 0,
        fearful: 0,
        disgusted: 0,
      },
      emotionsScore: {
        happy: 0,
        sad: 0,
        surprised: 0,
        angry: 0,
        fearful: 0,
        disgusted: 0,
      },
      emotionBarLength: 5,
      emotionsColors: EMOTIONS_COLORS,
      intervalId: -1,
    };
  },

  computed: {
    emotions() {
      return Object.keys(this.emotionsCount);
    },
  },

  sockets: {
    emotion({ expression, probability }) {
      this.emotionsCount[expression] += probability;
      this.updateEmotionsScore();
    },
  },

  mounted() {
    this.intervalId = setInterval(() => {
      this.decreaseEmotionsCount();
    }, 3000);
  },

  beforeUnmount() {
    clearInterval(this.intervalId);
  },

  methods: {
    updateEmotionsScore() {
      const emotionsCountTotal = this.getEmotionsCountTotal();
      for (const [key, value] of Object.entries(this.emotionsCount)) {
        this.emotionsScore[key] = this.getEmotionScore(
          value,
          emotionsCountTotal
        );
      }
    },
    getEmotionScore(emotionCount, emotionsCountTotal) {
      if (emotionsCountTotal === 0) {
        return 0;
      }
      return Math.round(
        (emotionCount / emotionsCountTotal) * this.emotionBarLength
      );
    },
    getEmotionsCountTotal() {
      let total = 0;
      for (const [key] of Object.entries(this.emotionsCount)) {
        total += this.emotionsCount[key];
      }
      return total;
    },
    decreaseEmotionsCount() {
      for (const [key, value] of Object.entries(this.emotionsCount)) {
        // if emotion count <= 25 set 0
        if (this.emotionsCount[key] <= 25) {
          this.emotionsCount[key] = 0;
          // else divide emotion count by 2
        } else {
          this.emotionsCount[key] = Math.round(value / 2);
        }
      }
      this.updateEmotionsScore();
    },
  },
};
</script>

<style lang="scss" scoped></style>
