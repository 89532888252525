<template>
  <cafe-layout />
</template>

<script>
import CafeLayout from "@/layouts/CafeLayout";
import { useQuasar } from "quasar";

export default {
  name: "LayoutDefault",

  components: {
    CafeLayout,
  },

  mounted() {
    const $q = useQuasar();

    // calling here; equivalent to when component is created
    $q.dark.set(true);
  },
};
</script>
<style lang="scss">
.body--dark {
  background: $black-4 !important;
}
</style>
