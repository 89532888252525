import { render } from "./BaseInput.vue?vue&type=template&id=2dac5445&scoped=true"
import script from "./BaseInput.vue?vue&type=script&lang=js"
export * from "./BaseInput.vue?vue&type=script&lang=js"

import "./BaseInput.vue?vue&type=style&index=0&id=2dac5445&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-2dac5445"

export default script
import QInput from 'quasar/src/components/input/QInput.js';
import QField from 'quasar/src/components/field/QField.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QInput,QField});
