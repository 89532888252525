export default {
  namespaced: true,
  state: {
    chatMessages: [],
    isChatOn: false,
    newMessagesQuantity: 0,
  },
  mutations: {
    ADD_CHAT_MESSAGE(state, { key, text, authorId, stamp }) {
      state.chatMessages.push({ key, text, authorId, stamp });
    },
    RESET_CHAT(state) {
      state.chatMessages = [];
      state.isChatOn = false;
      state.newMessagesQuantity = 0;
    },
    TOGGLE_CHAT(state) {
      state.isChatOn = !state.isChatOn;
    },
    INCREMENT_NEW_MESSAGE_QUANTITY(state) {
      state.newMessagesQuantity += 1;
    },
    RESET_NEW_MESSAGE_QUANTITY(state) {
      state.newMessagesQuantity = 0;
    },
  },
  actions: {
    addChatMessage({ commit }, { key, text, authorId, stamp }) {
      commit("ADD_CHAT_MESSAGE", { key, text, authorId, stamp });
    },
    resetChat({ commit }) {
      commit("RESET_CHAT");
    },
    toggleChat({ commit }) {
      commit("TOGGLE_CHAT");
      commit("RESET_NEW_MESSAGE_QUANTITY");
    },
    incrementNewMessagesQuantity({ commit }) {
      commit("INCREMENT_NEW_MESSAGE_QUANTITY");
    },
  },
};
