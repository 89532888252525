<template>
  <img class="logo" alt="logo" src="@/assets/logo_cafe.png" />
</template>

<script>
export default {
  name: "BaseLogo",
};
</script>

<style lang="scss" scoped>
.logo {
  width: 6.2rem;
  height: 6.4rem;
  object-fit: contain;
}
</style>
