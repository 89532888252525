<template>
  <div v-for="(probability, idx) in [25, 50, 75, 100]" :key="idx">
    <transition-group
      :name="`emotion${probability}`"
      @enter="enter"
      @after-enter="afterEnter"
    >
      <div
        v-for="(emotion, index) in emotions[probability]"
        :key="index"
        :class="`emotion${probability}--wrapper`"
      >
        <img
          :style="emotionSize(emotion)"
          :src="emotionSource(emotion)"
          alt="Emotion svg image"
        />
      </div>
    </transition-group>
  </div>
</template>

<script>
import * as constant from "@/utils/consts";
export default {
  name: "RoomSpacesPeerAnimationEmotion",

  props: {
    peerId: {
      type: String,
      required: true,
    },
  },

  emits: [constant.TOGGLE_ANIMATION],

  data() {
    return {
      emotions: {
        25: [],
        50: [],
        75: [],
        100: [],
      },
      receivedEmotionProbability: 0,
    };
  },

  sockets: {
    emotion({ expression, probability, peerId }) {
      if (peerId === this.peerId) {
        this.receivedEmotionProbability = probability;
        this.emotions[probability].push({ expression, probability });
      }
    },
  },

  methods: {
    toggleAnimation(value) {
      this.$emit(constant.TOGGLE_ANIMATION, value);
    },
    emotionSource({ expression }) {
      return require(`@/assets/emotions/${expression}.svg`);
    },
    emotionSize({ probability }) {
      let size = `${20 + probability / 5}px`;
      return {
        width: size,
        height: size,
      };
    },
    afterEnter(el) {
      el.remove();
      this.toggleAnimation(false);
    },
    enter(el) {
      let emotionsArray = this.emotions[this.receivedEmotionProbability];

      if (emotionsArray.length < constant.EMOTIONS_TABLE_MAX_LENGTH) {
        this.toggleAnimation(true);
      } else if (emotionsArray.length > constant.EMOTIONS_TABLE_MAX_LENGTH) {
        el.remove();
      } else {
        el.remove();
        setTimeout(() => {
          emotionsArray.splice(0);
        }, 1000);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@mixin emotion($emotionProbability, $duration, $method) {
  &--wrapper {
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
  }
  &-enter {
    &-from {
      opacity: 0;
      transform: translate(0);
    }
    &-active {
      @include animate(emotion + $emotionProbability, $duration, $method);
    }
    &-to {
      opacity: 1;
    }
  }
}
@keyframes emotion25 {
  10% {
    opacity: 0.5;
  }
  90% {
    transform: translate(0px, -10vh);
  }
  100% {
    opacity: 0;
  }
}
@keyframes emotion50 {
  10% {
    opacity: 0.75;
  }
  90% {
    transform: translate(0px, -15vh);
  }
  100% {
    opacity: 0;
  }
}
@keyframes emotion75 {
  10% {
    opacity: 1;
  }
  90% {
    transform: translate(0px, -20vh);
  }
  100% {
    opacity: 0;
  }
}
@keyframes emotion100 {
  10% {
    opacity: 1;
  }
  50% {
    transform: translate(0px, -20vh) scale(1.5, 1.5);
  }
  90% {
    transform: translate(0px, -30vh);
  }
  100% {
    opacity: 0;
    transform: translate(0px, -40vh);
  }
}
@each $probability in $probabilities {
  .emotion#{$probability} {
    @include emotion($probability, 1s, ease-in-out);
  }
}
</style>
