<template>
  <base-button :pressed="onDialog" v-bind="$attrs" @click="onDialog = true">
    <q-icon size="sm" name="settings" />
  </base-button>
  <q-dialog v-model="onDialog" @hide="setDevices">
    <q-card class="card">
      <q-card-section>
        <h6 class="q-ma-none">Available Devices</h6>
        <q-select
          v-model="selectedDevice.audioInput"
          :options="availableDevices.audioInput"
          label="Audio Input Devices"
          class="q-mb-md"
        />
        <q-select
          v-model="selectedDevice.audioOutput"
          :options="availableDevices.audioOutput"
          label="Audio Output Devices"
          :disable="!canChangeAudioOutput"
          :hint="audioOutputHint"
          class="q-mb-md"
        />
        <q-select
          v-model="selectedDevice.videoInput"
          :options="availableDevices.videoInput"
          label="Video Devices"
        />
      </q-card-section>
      <q-card-actions align="right">
        <q-btn v-close-popup size="16px" flat label="Ok" color="primary" />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import * as constant from "@/utils/consts";
import { mapActions, mapState } from "vuex";
import FaceExpressionMixin from "@/mixins/FaceExpressionMixin";
import BaseButton from "@/components/BaseButton";
import ProducerMixin from "@/mixins/ProducerMixin";
import DevicesMixin from "@/mixins/DevicesMixin";

export default {
  name: "RoomControlPanelSettings",

  components: { BaseButton },

  mixins: [FaceExpressionMixin, ProducerMixin, DevicesMixin],

  data() {
    return {
      onDialog: false,

      availableDevices: {
        audioInput: [],
        audioOutput: [],
        videoInput: [],
      },

      selectedDevice: {
        audioInput: {},
        audioOutput: {},
        videoInput: {},
      },
    };
  },

  computed: {
    ...mapState({
      mediaDevices: (state) => state.media.mediaDevices,
    }),
    audioOutputHint() {
      return !this.canChangeAudioOutput
        ? constant.AUDIO_OUTPUT_HINT
        : undefined;
    },
  },

  created() {
    this.DEVICE_TYPES = [
      constant.AUDIO_INPUT,
      constant.AUDIO_OUTPUT,
      constant.VIDEO_INPUT,
    ];
  },

  async mounted() {
    console.log("navigator", navigator);
    await this.loadAllDevices();
    await this.loadDefaultDevices();
  },

  methods: {
    ...mapActions({
      setMediaDevice: "media/mediaDevices/setMediaDevice",
    }),
    async loadAllDevices() {
      const devices = await navigator.mediaDevices.enumerateDevices();
      devices.forEach((device) => {
        let type = this.DEVICE_TYPES.find(
          (type) => type.toLowerCase() === device.kind
        );
        let option = { value: device.deviceId, label: device.label };
        this.availableDevices[type].push(option);
      });
    },
    hasDeviceChanged(type) {
      return this.mediaDevices[type].value !== this.selectedDevice[type].value;
    },
    async loadDefaultDevices() {
      for (const type of this.DEVICE_TYPES) {
        this.selectedDevice[type] =
          this.availableDevices[type][0] ?? "No devices";
        await this.setDevice(type, true);
      }
    },
    async setDevices() {
      for (const type of this.DEVICE_TYPES) {
        if (!this.hasDeviceChanged(type)) {
          continue;
        }
        await this.setDevice(type);
        if (type === constant.VIDEO_INPUT) {
          await this.initEmotionRecognition();
        }
      }
    },
    async setDevice(type, defaultDevice = false) {
      await this.setMediaDevice({
        type,
        defaultDevice,
        device: this.selectedDevice[type],
      });
      if (defaultDevice) {
        return;
      }
      await this.recreateProducer(
        type === constant.AUDIO_INPUT ? constant.AUDIO : constant.VIDEO
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  min-width: 30rem;
  border-radius: 1rem;
}
</style>
