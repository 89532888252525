import actionsEmit from "@/store/modules/socket/actionsEmit";
import actionsRequest from "@/store/modules/socket/actionsRequest";

export default {
  namespaced: true,
  modules: {
    actionsEmit,
    actionsRequest,
  },
  state: {
    socket: {},
  },
  mutations: {
    SET_SOCKET(state, { socket }) {
      state.socket = socket;
    },
  },
  actions: {
    setSocket({ commit }, context) {
      commit("SET_SOCKET", context);
    },
  },
};
