<template>
  <div class="column absolute-center items-center q-pa-none q-ma-none">
    <q-spinner-dots color="blue" size="160px" />
    <h4>{{ connectionMessage }}</h4>
  </div>
</template>
<script>
export default {
  name: "BaseLoading",

  props: {
    connectionMessage: {
      type: String,
      required: true,
    },
  },
};
</script>
