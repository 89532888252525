import { mapActions, mapState } from "vuex";
import * as constant from "@/utils/consts";

export default {
  computed: {
    ...mapState({
      device: (state) => state.mediasoupWrapper.device,
    }),
  },
  methods: {
    ...mapActions({
      createWebRtcTransport: "socket/actionsRequest/createWebRtcTransport",
      connectTransport: "socket/actionsRequest/connectTransport",
      produceTransport: "socket/actionsRequest/produceTransport",

      addProducerTransport:
        "mediasoupWrapper/mediasoupProducer/addProducerTransport",
      addConsumerTransport:
        "mediasoupWrapper/mediasoupConsumer/addConsumerTransport",
    }),
    async createSendTransport() {
      let transport;
      try {
        const webRtcTransport = await this.createWebRtcTransport();
        transport = await this.device.createSendTransport(webRtcTransport);
      } catch (error) {
        console.log(error);
        return;
      }
      this.addProducerTransport({ transport });
      await this.addTransportConnectEvent(transport);
      await this.addTransportProduceEvent(transport);
      await this.addTransportStateChangeEvent(transport);
    },
    async createRecvTransport() {
      let transport;
      try {
        const webRtcTransport = await this.createWebRtcTransport();
        transport = await this.device.createRecvTransport(webRtcTransport);
      } catch (error) {
        console.log(error);
        return;
      }
      this.addConsumerTransport({ transport });
      await this.addTransportConnectEvent(transport);
      await this.addTransportStateChangeEvent(transport);
    },
    async addTransportConnectEvent(transport) {
      transport.on(
        constant.CONNECT,
        ({ dtlsParameters }, callback, errback) => {
          try {
            this.connectTransport({
              dtlsParameters,
              transportId: transport.id,
              callback,
              errback,
            });
            callback();
          } catch (error) {
            errback(error);
          }
        }
      );
    },
    async addTransportProduceEvent(transport) {
      transport.on(
        constant.PRODUCE,
        async ({ kind, rtpParameters }, callback, errback) => {
          console.log("PRODUCER TRANSPORT ON PRODUCE");
          try {
            const { producerId } = await this.produceTransport({
              producerTransportId: transport.id,
              kind,
              rtpParameters,
            });
            callback({
              id: producerId,
            });
          } catch (err) {
            errback(err);
          }
        }
      );
    },
    async addTransportStateChangeEvent(transport) {
      transport.on(constant.CONNECTION_STATE_CHANGE, async (state) => {
        console.log(`${constant.MESSAGE_TRANSPORT_STATE}: ${state}`);
      });
    },
  },
};
