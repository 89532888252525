import { mapActions } from "vuex";
import ConsumerPrivateMixin from "@/mixins/private/ConsumerPrivateMixin";

export default {
  mixins: [ConsumerPrivateMixin],

  methods: {
    ...mapActions({
      createConsumerStore: "mediasoupWrapper/mediasoupConsumer/createConsumer",
      closeConsumerStore: "mediasoupWrapper/mediasoupConsumer/closeConsumer",
      pauseConsumerStore: "mediasoupWrapper/mediasoupConsumer/pauseConsumer",
      resumeConsumerStore: "mediasoupWrapper/mediasoupConsumer/resumeConsumer",
    }),
    async createConsumer(producer, peerId, spaceId) {
      const { consumer, mediaElement } = await this._createConsumerWithMedia(
        producer.id,
        peerId,
        spaceId
      );
      if (!consumer) {
        return;
      }

      if (producer.paused) {
        consumer.pause();
      }
      await this.createConsumerStore({
        consumer,
        mediaElement,
        peerId,
        spaceId,
      });
    },
    async closeConsumer(consumerId, peerId, spaceId) {
      this.closeConsumerStore({
        consumerId,
        peerId,
        spaceId,
      });
    },
    async pauseConsumer(consumerId) {
      await this.pauseConsumerStore({ consumerId });
    },
    async resumeConsumer(consumerId) {
      await this.resumeConsumerStore({ consumerId });
    },
  },
};
