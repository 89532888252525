import * as constant from "@/utils/consts";
import { mapActions } from "vuex";
import FaceExpressionMixin from "./FaceExpressionMixin";
import ProducerMixin from "@/mixins/ProducerMixin";

export default {
  mixins: [FaceExpressionMixin, ProducerMixin],
  data() {
    return {
      isScreenSharingLoading: false,
      isScreenOn: false,
    };
  },
  methods: {
    ...mapActions({
      createStream: "media/ownStreams/createStream",
    }),
    async shareScreen(isVideoOn) {
      this.isScreenOn = !this.isScreenOn;
      this.isScreenOn
        ? await this.turnOnSharing(isVideoOn)
        : await this.turnOffSharing(isVideoOn);
    },
    async turnOnSharing(isVideoOn) {
      this.isScreenSharingLoading = true;

      const stream = await this.createStream({
        type: constant.SCREEN_SHARE,
      });
      if (stream) {
        if (!isVideoOn) {
          await this.resumeProducer(constant.VIDEO);
        }
        await this.replaceProducerStreamTrack(constant.SCREEN_SHARE);
      } else {
        this.isScreenOn = false;
      }
      this.isScreenSharingLoading = false;
    },
    async turnOffSharing(isVideoOn) {
      await this.recreateVideoStream();
      await this.replaceProducerStreamTrack(constant.VIDEO);
      if (!isVideoOn) {
        await this.pauseProducer(constant.VIDEO);
      }
    },
    async recreateVideoStream() {
      await this.createStream({
        type: constant.VIDEO,
      });
      await this.initEmotionRecognition();
    },
  },
};
