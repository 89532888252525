import { mapGetters, mapActions } from "vuex";
import ProducerPrivateMixin from "@/mixins/private/ProducerPrivateMixin";
import * as constant from "@/utils/consts";

export default {
  mixins: [ProducerPrivateMixin],

  computed: {
    ...mapGetters({
      getProducer: "mediasoupWrapper/mediasoupProducer/getProducer",
      isMediaOn: "mediasoupWrapper/mediasoupProducer/isMediaOn",

      getStream: "media/ownStreams/getStream",
    }),
  },

  methods: {
    ...mapActions({
      closeProducerSocket: "socket/actionsEmit/closeProducer",
      pauseProducerSocket: "socket/actionsEmit/pauseProducer",
      resumeProducerSocket: "socket/actionsEmit/resumeProducer",

      createProducerStore: "mediasoupWrapper/mediasoupProducer/createProducer",
      closeProducerStore: "mediasoupWrapper/mediasoupProducer/closeProducer",
      pauseProducerStore: "mediasoupWrapper/mediasoupProducer/pauseProducer",
      resumeProducerStore: "mediasoupWrapper/mediasoupProducer/resumeProducer",
      replaceProducerStreamTrackStore:
        "mediasoupWrapper/mediasoupProducer/replaceProducerStreamTrack",
    }),
    async createProducer(type) {
      if (!this._canCreateProducer(type)) {
        return;
      }
      try {
        const producerWithMedia = await this._createProducerWithMedia(type);
        await this.createProducerStore({ type, producerWithMedia });
      } catch (err) {
        console.log(constant.PRODUCER_CREATE_ERROR, err);
      }
    },
    async closeProducer(type) {
      const producer = this.getProducer(type);
      if (!producer) {
        return;
      }
      this.closeProducerSocket({ producerId: producer.id });
      await this.closeProducerStore({ type, producer });
    },
    async pauseProducer(type) {
      const producerId = this.getProducer(type)?.id;
      if (!producerId) {
        return;
      }
      this.pauseProducerSocket({ producerId });
      this.pauseProducerStore({ producerId });
    },
    async resumeProducer(type) {
      const producerId = this.getProducer(type)?.id;
      if (!producerId) {
        return;
      }
      this.resumeProducerSocket({ producerId });
      this.resumeProducerStore({ producerId });
    },
    async recreateProducer(type) {
      let isProducerOn = this.isMediaOn(type);
      await this.closeProducer(type);
      if (isProducerOn) {
        await this.createProducer(type);
      }
    },
    async toggleProducer(type) {
      const producer = this.getProducer(type);
      if (!producer) {
        return;
      }
      producer.paused
        ? await this.resumeProducer(type)
        : await this.pauseProducer(type);
    },
    async replaceProducerStreamTrack(streamType) {
      const stream = this.getStream({ type: streamType });
      const producerId = this.getProducer(constant.VIDEO)?.id;
      if (!stream || !producerId) {
        return;
      }

      const mediaElement = {
        isScreen: streamType === constant.SCREEN_SHARE,
        id: producerId,
        kind: constant.VIDEO,
        srcObject: stream,
      };
      await this.replaceProducerStreamTrackStore({
        mediaElement,
        producerId,
      });
    },
  },
};
