<template>
  <div
    :class="[
      'q-mt-md',
      'q-gutter-md ',
      isMobilePortrait ? 'row reverse' : 'column q-mr-lg',
      {
        'justify-evenly': isMobile,
      },
    ]"
  >
    <base-button class="button--exit" @click="exit">
      <q-icon size="sm" name="logout" />
    </base-button>
    <room-control-panel-settings :class="{ 'q-mb-md': !isMobilePortrait }" />
    <base-button
      data-cy="toggle-video-button"
      class="q-mt-md"
      :disable="isScreenOn"
      :pressed="isVideoOn"
      @click="toggleVideo"
    >
      <q-icon size="sm" name="photo_camera" />
    </base-button>
    <base-button
      data-cy="lobby-mode-button"
      :disable="isScreenOn"
      :pressed="isLobbyMode"
      @click="toggleLobbyMode"
    >
      <q-icon size="sm" name="mic_off" />
    </base-button>
    <template v-if="!isMobile">
      <base-button
        data-cy="chat-button"
        :disable="isScreenOn"
        :pressed="isChatOn"
        @click="toggleChat"
      >
        <q-icon size="sm" name="chat" />
        <q-badge v-if="newMessagesQuantity && !isChatOn" color="purple">
          {{ newMessagesQuantity }}
        </q-badge>
      </base-button>
      <base-button
        data-cy="screen-button"
        :disable="isScreenSharingLoading"
        :pressed="isScreenOn"
        @click="() => shareScreen(isVideoOn)"
      >
        <q-icon size="sm" name="screen_share" />
      </base-button>
      <base-button :pressed="isBarometerOn" @click="toggleBarometer">
        <q-icon size="sm" name="sentiment_very_satisfied" />
      </base-button>
    </template>
    <room-control-panel-barometer-emotion v-if="isBarometerOn" />
  </div>
</template>

<script>
import BaseButton from "@/components/BaseButton";
import RoomControlPanelSettings from "@/components/RoomControlPanelSettings.vue";
import RoomControlPanelBarometerEmotion from "@/components/RoomControlPanelBarometerEmotion.vue";
import * as constant from "@/utils/consts";
import { mapActions, mapGetters, mapState } from "vuex";
import FaceExpressionMixin from "@/mixins/FaceExpressionMixin";
import ScreenSharingMixin from "@/mixins/ScreenSharingMixin";
import ProducerMixin from "@/mixins/ProducerMixin";
import ViewportMixin from "@/mixins/ViewportMixin";

export default {
  name: "RoomControlPanel",

  components: {
    RoomControlPanelSettings,
    RoomControlPanelBarometerEmotion,
    BaseButton,
  },

  mixins: [
    FaceExpressionMixin,
    ScreenSharingMixin,
    ProducerMixin,
    ViewportMixin,
  ],

  data() {
    return {
      isVideoOn: false,
      isBarometerOn: false,
    };
  },

  computed: {
    ...mapState({
      isChatOn: (state) => state.chat.isChatOn,
      newMessagesQuantity: (state) => state.chat.newMessagesQuantity,

      isLobbyMode: (state) => state.room.isLobbyMode,
    }),
    ...mapGetters({
      getUserAmount: "room/spaces/getUserAmount",
    }),
    getCameraIcon() {
      return this.isVideoOn
        ? require("@/assets/custom-video-icon.svg")
        : require("@/assets/custom-video-icon-mute.svg");
    },
  },

  async mounted() {
    if (this.getUserAmount < 2) {
      this.isVideoOn = true;
      await this.createProducer(constant.VIDEO);
    }
    await this.createProducer(constant.AUDIO);
  },

  methods: {
    ...mapActions({
      toggleLobbyMode: "room/toggleLobbyMode",

      toggleChat: "chat/toggleChat",
      resetChat: "chat/resetChat",

      exitRoom: "socket/actionsRequest/exitRoom",
    }),
    toggleVideo() {
      this.isVideoOn = !this.isVideoOn;
      this.toggleProducer(constant.VIDEO);
    },
    toggleBarometer() {
      this.isBarometerOn = !this.isBarometerOn;
    },
    exit() {
      this.resetChat();
      this.exitRoom({ offline: false });
      this.$router.push({ name: "landing" });
    },
  },
};
</script>

<style lang="scss" scoped>
.button {
  &--exit {
    background-color: $red !important;
    color: $white;
  }
}
</style>
