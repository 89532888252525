<template>
  <div
    :class="[
      {
        'peer--scene reverse': isScene(spaceId),
      },
      'peer',
      'column',
      'relative-position',
    ]"
  >
    <base-avatar
      :class="[
        {
          'peer__avatar--owner': peer.owner,
          'peer__avatar--animation': isAnimationActive,
        },
        'relative-position',
      ]"
      :peer="peer"
      :size="isSceneAndNoVideoOnDesktop(spaceId) ? 'lg' : 'md'"
    >
      <room-spaces-peer-animation-emotion
        :peer-id="peerId"
        @toggle-animation="toggleAnimation"
      />
    </base-avatar>
    <span
      :class="[
        isSceneAndNoVideoOnDesktop(spaceId)
          ? 'peer__name--scene text-h2'
          : 'text-h6',
        'overflow-hidden',
        'text-center',
        'ellipsis',
        'q-mb-xs',
        'q-mt-xs',
        'peer__name',
      ]"
    >
      {{ peer.peerName }}
    </span>
    <video ref="video" class="hidden" autoplay="" muted="" playsinline="" />
    <audio ref="audioOutput" v-bind="peer.audio" autoplay />
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import RoomSpacesPeerAnimationEmotion from "@/components/RoomSpacesPeerAnimationEmotion";
import BaseAvatar from "@/components/BaseAvatar";
import ViewportMixin from "@/mixins/ViewportMixin";
import SpacesMixin from "@/mixins/SpacesMixin";
import DevicesMixin from "@/mixins/DevicesMixin";

export default {
  name: "RoomSpacesPeer",

  components: { BaseAvatar, RoomSpacesPeerAnimationEmotion },

  mixins: [ViewportMixin, SpacesMixin, DevicesMixin],

  props: {
    peer: {
      type: Object,
      required: true,
    },
    peerId: {
      type: String,
      required: true,
    },
    spaceId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      isAnimationActive: false,
    };
  },

  computed: {
    ...mapState({
      audioOutput: (state) => state.media.mediaDevices.audioOutput,
    }),
    ...mapGetters({
      getPeer: "room/spaces/getPeer",
    }),
    getAvatar() {
      let avatar =
        this.getPeer({
          spaceId: this.spaceId,
          peerId: this.peerId,
        })?.avatar ?? require("@/assets/avatar_blank.svg");
      return {
        "background-image": `url(${avatar})`,
      };
    },
  },

  watch: {
    audioOutput(device) {
      this.setAudioOutput(device.value);
    },
  },

  mounted() {
    this.setAudioOutput(this.audioOutput.value);
  },

  methods: {
    toggleAnimation(animationActive) {
      this.isAnimationActive = animationActive;
    },
    setAudioOutput(device) {
      if (!this.canChangeAudioOutput) {
        return;
      }
      const audioOutput = this.$refs.audioOutput;
      audioOutput?.setSinkId(device);
    },
  },
};
</script>

<style lang="scss" scoped>
@keyframes peer-border {
  0%,
  20% {
    border-color: $gold;
  }
}
.peer {
  font-family: $font-primary;
  height: min-content;
  align-items: center;
  &--scene {
    transform: translateY(-30%);
  }
  &__name {
    width: 5rem;
    direction: ltr;
    &--scene {
      width: 10rem;
    }
  }
  &__avatar {
    &--owner {
      border: 0.4rem solid $blue;
    }
    &--animation {
      animation: peer-border 2s cubic-bezier(0.09, 0.33, 0, 1.11);
    }
  }
}
</style>
