<template>
  <div
    data-cy="scene"
    :class="[
      {
        'scene-desktop--expanded': anyPeerSceneVideo,
        'scene-mobile max-width': isMobile,
        'scene-mobile--landscape': isMobileLandscape,
      },
      'scene-desktop',
      'flex',
    ]"
  >
    <div
      v-if="!isMobile"
      class="flex full-height full-width relative-position q-mt-xl"
    >
      <video
        v-for="(peer, index) of peersSceneVideo"
        :key="index"
        :class="[
          { 'scene-desktop__video--owner': isVideoCameraOwner(peer) },
          'scene-desktop__video',
          'full-height',
          'col',
        ]"
        v-bind="peer.video"
        autoplay=""
        muted=""
        playsinline=""
      />
      <room-spaces-scene-chat v-show="isChatOn" class="absolute-right" />
    </div>
    <base-space
      :class="{ 'absolute-center': !isMobile }"
      :space="scene"
      :space-id="sceneId"
      :size="peerWithoutVideoOnDesktop ? 'sm' : 'xs'"
      @change-space="changeSpace"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import RoomSpacesSceneChat from "@/components/RoomSpacesSceneChat";
import * as constant from "@/utils/consts";
import BaseSpace from "@/components/BaseSpace";
import ViewportMixin from "@/mixins/ViewportMixin";

export default {
  name: "RoomSpacesScene",

  components: {
    BaseSpace,
    RoomSpacesSceneChat,
  },

  mixins: [ViewportMixin],

  props: {
    scene: {
      type: Map,
      required: true,
    },
  },

  emits: [constant.CHANGE_SPACE],

  data() {
    return {
      sceneId: constant.spacesEnum.SCENE,
    };
  },

  computed: {
    ...mapState({
      isChatOn: (state) => state.chat.isChatOn,
    }),
    ...mapGetters({
      peersWithCameraOnScene: "room/spaces/peersCameraOnScene",
    }),
    peersSceneVideo() {
      return this.peersWithCameraOnScene.slice(0, 3);
    },
    anyPeerSceneVideo() {
      return this.peersSceneVideo.length > 0;
    },
    peerWithoutVideoOnDesktop() {
      return this.peersWithCameraOnScene.length === 0 && !this.isMobile;
    },
  },

  methods: {
    isVideoCameraOwner(peer) {
      return peer.owner && !peer.video.isScreen;
    },
    changeSpace() {
      this.$emit(constant.CHANGE_SPACE, this.sceneId);
    },
  },
};
</script>

<style lang="scss" scoped>
.scene-desktop {
  transition: height 0.5s;
  height: 62vh;
  width: 72vw;
  margin-top: 6.7rem;
  &--expanded {
    & > :last-child {
      top: auto;
    }
  }
  &__video {
    border-radius: 1.6rem;
    object-fit: cover;
    width: 0;
    &--owner {
      transform: scaleX(-1);
    }
  }
}
.scene-mobile {
  transition: none;
  min-width: 35.8rem;
  height: $standard-size-xs;
  width: min-content;
  margin-top: 14.1rem;
  &--landscape {
    margin-top: 5.7rem;
  }
}
</style>
