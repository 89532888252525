<template>
  <q-btn
    :class="[
      `button__theme--${currentTheme}`,
      `button__size--${size}`,
      'button',
      'text-bold',
      'text-h3',
    ]"
    no-caps
    dense
    @click="onClick"
  >
    <slot />
  </q-btn>
</template>

<script>
import * as constant from "@/utils/consts";

export default {
  name: "BaseButton",

  props: {
    theme: {
      type: String,
      default: constant.DARK_THEME,
      validator: (value) => {
        return [constant.LIGHT_THEME, constant.DARK_THEME].includes(value);
      },
    },
    size: {
      type: String,
      default: "xs",
      validator: (value) => {
        return ["xs", "sm", "md", "lg", "xl"].includes(value);
      },
    },
    pressed: {
      type: Boolean,
      default: false,
    },
  },

  emits: ["click"],

  computed: {
    getReverseTheme() {
      return this.theme === constant.LIGHT_THEME
        ? constant.DARK_THEME
        : constant.LIGHT_THEME;
    },
    currentTheme() {
      return this.pressed ? this.getReverseTheme : this.theme;
    },
  },

  methods: {
    onClick(e) {
      this.$emit("click", e);
    },
  },
};
</script>

<style lang="scss" scoped>
.button {
  height: $standard-size-xs;
  border-radius: $button-border-radius-md;
  padding: 0.7rem 1.6rem;
  text-align: center;

  &:disabled {
    background-color: $grey-5;
    color: $grey-6;
  }

  &__theme {
    &--light {
      color: $black-4;
      background-color: $violet;
      &:hover,
      &:active {
        background-color: $violet-3;
      }
      &:focus {
        border: solid 0.3rem $violet-2;
        background-color: $violet-3;
      }
    }
    &--dark {
      color: $violet;
      background-color: $black-4;
      border: solid 0.1rem $grey-5;
      &:hover,
      &:active {
        background-color: $grey-5;
      }
      &:focus {
        border: solid 0.3rem $violet-2;
        background-color: $grey-5;
      }
    }
  }
  &__size {
    &--xs {
      width: $standard-size-xs;
    }

    &--sm {
      width: $standard-size-sm;
    }

    &--md {
      width: $button-width-md;
    }

    &--lg {
      width: $button-width-lg;
    }

    &--xl {
      width: $standard-width;
    }
  }
}
</style>
