<template>
  <div class="flex room no-wrap">
    <div
      v-if="!isMobile"
      class="absolute room__logo-and-info row no-wrap q-gutter-md"
    >
      <base-logo />
      <the-room-info />
    </div>
    <room-spaces class="full-width items-center" @change-space="changeSpace" />
    <room-control-panel
      :class="[
        {
          'absolute-top': isMobilePortrait,
          'absolute-right': isMobileLandscape,
          'absolute-top-right': !isMobile,
        },
      ]"
    />
  </div>
</template>

<script>
import RoomSpaces from "@/components/RoomSpaces.vue";
import RoomControlPanel from "@/components/RoomControlPanel";
import { mapActions, mapState } from "vuex";
import { AUDIO } from "@/utils/consts";
import BaseLogo from "@/components/BaseLogo";
import ProducerMixin from "@/mixins/ProducerMixin";
import TheRoomInfo from "@/components/TheRoomInfo";
import ViewportMixin from "@/mixins/ViewportMixin";

export default {
  name: "MeetingRoomView",

  components: {
    TheRoomInfo,
    BaseLogo,
    RoomControlPanel,
    RoomSpaces,
  },

  mixins: [ProducerMixin, ViewportMixin],

  computed: {
    ...mapState({
      userSpaceId: (state) => state.user.spaceId,
    }),
  },

  methods: {
    ...mapActions({
      getProducers: "socket/actionsEmit/getProducers",
      socketChangeSpace: "socket/actionsEmit/changeSpace",

      removeConsumersAndInformProducers:
        "mediasoupWrapper/mediasoupProducer/removeConsumersAndInformProducers",
    }),
    async changeSpace(spaceId) {
      await this.socketChangeSpace({ spaceId, oldSpaceId: this.userSpaceId });
      await this.removeConsumersAndInformProducers();
      await this.getProducers({ isAudioOnly: true });
      await this.recreateProducer(AUDIO);
    },
  },
};
</script>

<style lang="scss" scoped>
.room {
  &__logo-and-info {
    top: 1.6rem;
    left: 1.6rem;
  }
}
</style>
