<template>
  <div class="on-left column">
    <span>frontend version: {{ frontendVersion }}</span>
    <span>backend version: {{ backendVersion }}</span>
  </div>
</template>

<script>
export default {
  name: "DevTools",

  data() {
    return {
      frontendVersion: process.env.VUE_APP_VERSION,
      backendVersion: "",
    };
  },

  async mounted() {
    const backendVersionResponse = await fetch(
      `${process.env.VUE_APP_BACKEND_ADDR}/version`
    );
    this.backendVersion = await backendVersionResponse.text();
  },
};
</script>
