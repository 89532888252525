import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Quasar from 'quasar/src/vue-plugin.js';;
import quasarUserOptions from "./quasar-user-options";
import VueSocketIOExt from "vue-socket.io-extended";
import { io } from "socket.io-client";

let options = { transports: ["websocket"], rejectUnauthorized: false };

const backend = process.env.VUE_APP_BACKEND_ADDR;
const socket = io(backend, options);

socket.request = function request(type, data = {}) {
  return new Promise((resolve, reject) => {
    socket.emit(type, data, (data) => {
      if (data.error) {
        reject(data.error);
      } else {
        resolve(data);
      }
    });
  });
};

const app = createApp(App).use(Quasar, quasarUserOptions);
app.use(router);
app.use(store);
app.use(VueSocketIOExt, socket, { store });

app.mount("#app");
