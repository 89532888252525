import { render } from "./MeetingJoinView.vue?vue&type=template&id=f1d05a30&scoped=true"
import script from "./MeetingJoinView.vue?vue&type=script&lang=js"
export * from "./MeetingJoinView.vue?vue&type=script&lang=js"

import "./MeetingJoinView.vue?vue&type=style&index=0&id=f1d05a30&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-f1d05a30"

export default script
import QForm from 'quasar/src/components/form/QForm.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QForm,QIcon});
