<template>
  <a v-if="isExternal" class="link" :href="to"><slot /></a>
  <router-link v-else class="link" :to="to"><slot /></router-link>
</template>

<script>
export default {
  name: "BaseLink",

  props: {
    isExternal: {
      type: Boolean,
      default: false,
    },
    to: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.link {
  text-underline-offset: 0.4rem;
  color: $white;
  &:focus {
    background-color: $violet-4;
  }
  &:focus,
  &:hover {
    text-decoration-thickness: 0.3rem;
  }
}
</style>
