import * as faceapi from "face-api.js";
import store from "@/store";

export default {
  methods: {
    async beforeInitEmotionRecognition() {
      await faceapi.nets.tinyFaceDetector.loadFromUri("/models");
      await faceapi.nets.faceExpressionNet.loadFromUri("/models");
      let newImage = this.getImage();
      await this.detectFace(newImage);
    },
    async initEmotionRecognition() {
      let video = document.createElement("video");
      video.setAttribute("autoplay", "");
      video.setAttribute("muted", "");
      video.setAttribute("playsinline", "");
      if ("srcObject" in video) {
        video.srcObject = store.state.media.ownStreams.video.stream;
      } else {
        video.src = window.URL.createObjectURL(
          store.state.media.ownStreams.video.stream
        );
      }
      video.addEventListener("loadeddata", async () => {
        await video.play();

        const intervalId = setInterval(async () => {
          const expressionObjectToSend = await this.detectFace(video);
          if (expressionObjectToSend) {
            await store.dispatch("socket/actionsRequest/sendEmotion", {
              expressionObjectToSend,
            });
          }
        }, 500);
        await store.dispatch("room/setEmotionsIntervalId", { intervalId });
      });
    },
    getImage() {
      let newImage = new Image(224, 224);
      newImage.src = require("@/assets/avatar_blank.svg");
      return newImage;
    },
    matchDimensions(canvasObject) {
      const canvas = faceapi.createCanvas(canvasObject);
      return faceapi.matchDimensions(canvas, canvasObject, true);
    },
    async detectFace(userVideo) {
      let modelOptions = new faceapi.TinyFaceDetectorOptions();
      const detections = await faceapi
        .detectAllFaces(userVideo, modelOptions)
        .withFaceExpressions();
      const displaySize = this.matchDimensions(userVideo);
      const resizedDetections = faceapi.resizeResults(detections, displaySize);
      return this.faceExpression(resizedDetections);
    },
    faceExpression(expressionInput) {
      const expressionArray = Array.isArray(expressionInput)
        ? expressionInput
        : [expressionInput];
      const expr = expressionArray[0]?.expressions;
      const sorted = expr?.asSortedArray();
      const filtered = sorted?.filter((expr) => expr.probability > 0.1)[0];
      if (!filtered || filtered["expression"] === "neutral") {
        return;
      }

      return this.createObjectWithExpression(
        filtered["expression"],
        this.setProbability(filtered["probability"])
      );
    },
    setProbability(expressionProbability) {
      let probability = 0;
      if (expressionProbability >= 0 && expressionProbability <= 0.25) {
        probability = 25;
      } else if (expressionProbability > 0.25 && expressionProbability <= 0.5) {
        probability = 50;
      } else if (expressionProbability > 0.5 && expressionProbability <= 0.75) {
        probability = 75;
      } else {
        probability = 100;
      }
      return probability;
    },
    createObjectWithExpression(expression, probability) {
      return { expression, probability };
    },
  },
};
