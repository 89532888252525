import { render } from "./BaseLinkBack.vue?vue&type=template&id=21a0955d&scoped=true"
import script from "./BaseLinkBack.vue?vue&type=script&lang=js"
export * from "./BaseLinkBack.vue?vue&type=script&lang=js"

import "./BaseLinkBack.vue?vue&type=style&index=0&id=21a0955d&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-21a0955d"

export default script
import QIcon from 'quasar/src/components/icon/QIcon.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QIcon});
