<template>
  <q-dialog
    auto-close
    style="backdrop-filter: blur(1.5em)"
    :model-value="isLobbyMode"
    @before-show="enterLobby"
    @hide="exitLobby"
  >
    <q-card class="transparent" flat>
      <q-card-section class="text-lato text-center no-wrap">
        <div
          :class="[{ 'text-h2': isMobile }, 'text-h1', 'q-mb-md', 'text-bold']"
        >
          Lobby mode
        </div>
        <div
          :class="[{ 'text-h4': isMobile }, 'text-h3', 'q-pt-none', 'q-mt-md']"
        >
          You can hear the scene, but no one can hear you! Tap anywhere to come
          back to the meeting.
        </div>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>
<script>
import { mapActions, mapState } from "vuex";
import * as constant from "@/utils/consts";
import FaceExpressionMixin from "@/mixins/FaceExpressionMixin";
import ProducerMixin from "@/mixins/ProducerMixin";
import ViewportMixin from "@/mixins/ViewportMixin";

export default {
  name: "RoomSpacesLobby",

  mixins: [FaceExpressionMixin, ProducerMixin, ViewportMixin],

  emits: [constant.CHANGE_SPACE],

  data() {
    return {
      oldUserSpaceId: constant.spacesEnum.SCENE,
    };
  },

  computed: {
    ...mapState({
      userSpaceId: (state) => state.user.spaceId,

      isLobbyMode: (state) => state.room.isLobbyMode,
    }),
  },

  methods: {
    ...mapActions({
      toggleLobbyMode: "room/toggleLobbyMode",
    }),
    changeSpace(spaceId) {
      this.$emit(constant.CHANGE_SPACE, spaceId);
    },
    async enterLobby() {
      this.oldUserSpaceId = this.userSpaceId;
      this.changeSpace(constant.spacesEnum.LOBBY);
    },
    async exitLobby() {
      this.toggleLobbyMode();
      this.changeSpace(this.oldUserSpaceId);
      await this.initEmotionRecognition();
    },
  },
};
</script>

<style lang="scss" scoped>
.text-lato {
  font-family: $font-primary;
}
</style>
