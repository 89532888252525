<template>
  <q-form class="form column q-mx-auto q-mt-lg" @submit="onSubmit">
    <base-link-back />
    <h1 class="text-bold q-mt-md q-mb-xl">Join meeting</h1>
    <base-input
      v-model="meetingName"
      data-cy="meeting-name-button"
      class="q-mb-lg"
      :rules="getNameMeetingRules"
      name="meeting-name"
    >
      Meeting name
    </base-input>
    <base-input
      v-model="userName"
      data-cy="meeting-name-button"
      class="q-mb-lg"
      :rules="getNameUserRules"
      name="meeting-name"
    >
      Your name
    </base-input>
    <div class="flex items-center justify-between q-mb-xl">
      <base-avatar size="lg" />
      <base-button
        size="lg"
        :disabled="isPhotoTaking"
        @click="takePhotoAndSetAvatar"
      >
        <q-icon size="sm" name="photo_camera" />
        <span class="q-ml-sm">Take photo</span>
      </base-button>
    </div>
    <base-button size="md" theme="light" type="submit" label="Join" />
  </q-form>
</template>

<script>
import { mapActions } from "vuex";
import BaseLinkBack from "@/components/BaseLinkBack";
import BaseInput from "@/components/BaseInput";
import BaseButton from "@/components/BaseButton";
import BaseAvatar from "@/components/BaseAvatar";
import PhotoMixin from "@/mixins/PhotoMixin";
import * as constant from "@/utils/consts";

export default {
  name: "MeetingJoinView",

  components: {
    BaseAvatar,
    BaseButton,
    BaseInput,
    BaseLinkBack,
  },

  mixins: [PhotoMixin],

  data() {
    return {
      meetingName: "",
      userName: "",
      isPhotoTaking: false,
    };
  },

  computed: {
    getNameMeetingRules() {
      return [
        (meetingName) =>
          (meetingName && meetingName.length > 0) ||
          "Please specify meeting name",
        (meetingName) => meetingName.indexOf("/") < 0 || "Slash is not allowed",
      ];
    },
    getNameUserRules() {
      return [
        (userName) =>
          (userName && userName.length > 0) || "Please specify username",
      ];
    },
    getCameraIcon() {
      return require("@/assets/custom-video-icon-mute.svg");
    },
  },

  mounted() {
    if (this.$route.name === constant.JOIN && this.$route.params["name"]) {
      this.meetingName = this.$route.params["name"];
    }
  },

  methods: {
    ...mapActions({
      setUserName: "user/setName",
      setAvatar: "user/setAvatar",

      setRoomName: "room/setName",
    }),
    onSubmit() {
      this.setUserName({ userName: this.userName });
      this.setRoomName({ roomName: this.meetingName });
      this.$router.push({
        name: constant.ROOM,
        params: { name: this.meetingName },
      });
    },
    async takePhotoAndSetAvatar() {
      this.isPhotoTaking = true;
      const avatar = await this.takePhoto();
      this.setAvatar({ avatar });
      this.isPhotoTaking = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  font-family: $font-primary;
  width: $standard-width;
  @include breakpoint.up(tablet) {
    @include center-element();
  }
}
</style>
