import { createRouter, createWebHistory } from "vue-router";
import LandingView from "@/views/LandingView";
import MediasoupConnection from "@/views/MediasoupConnection";
import MeetingJoinView from "@/views/MeetingJoinView";
import MeetingNewView from "@/views/MeetingNewView";
import * as constant from "@/utils/consts";

const routes = [
  {
    path: "/",
    name: constant.LANDING,
    component: LandingView,
  },
  {
    path: "/room/:name",
    name: constant.ROOM,
    component: MediasoupConnection,
  },
  {
    path: "/meeting",
    name: constant.MEETING,
    component: MeetingNewView,
  },
  {
    path: "/join/:name?",
    name: constant.JOIN,
    component: MeetingJoinView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
