<template>
  <div class="flex">
    <div class="col flex justify-center q-mr-sm">
      <q-icon :name="imgPath" />
    </div>
    <div v-for="i in emotionScore" :key="i" class="col q-ml-xs">
      <div class="rectangle" />
    </div>
    <div v-for="i in tilesNotFilled" :key="i" class="col q-ml-xs">
      <div class="rectangle__default" />
    </div>
  </div>
</template>

<script>
export default {
  name: "RoomControlPanelBarometerEmotionIcon",

  props: {
    emotionBarLength: {
      type: Number,
      required: true,
    },
    emotionName: {
      type: String,
      required: true,
    },
    emotionScore: {
      type: Number,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
  },

  computed: {
    imgPath() {
      const imgUrl = require(`@/assets/emotions/${this.emotionName}.svg`);
      return `img:${imgUrl}`;
    },
    tilesNotFilled() {
      return this.emotionBarLength - this.emotionScore;
    },
  },
};
</script>

<style lang="scss" scoped>
.rectangle {
  width: 6px;
  height: 14px;
  border-radius: 3px;
  background-color: v-bind(color);
  &__default {
    @extend .rectangle;
    background-color: $grey-4;
  }
}
</style>
