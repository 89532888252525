import * as constant from "@/utils/consts";

export default {
  namespaced: true,
  state: {
    consumers: new Map(),
    consumerTransport: {},
  },
  mutations: {
    CREATE_CONSUMER(state, { consumer }) {
      state.consumers.set(consumer.id, consumer);
    },
    REMOVE_CONSUMER(state, { consumerId }) {
      const consumer = state.consumers.get(consumerId);
      if (!consumer) {
        return;
      }
      consumer.close();
      state.consumers.delete(consumerId);
    },
    PAUSE_CONSUMER(state, { consumerId }) {
      state.consumers.get(consumerId).pause();
    },
    RESUME_CONSUMER(state, { consumerId }) {
      state.consumers.get(consumerId).resume();
    },
    ADD_CONSUMER_TRANSPORT(state, { transport }) {
      state.consumerTransport = transport;
    },
    CLEAR_STATE(state) {
      state.consumers = new Map();
      state.consumerTransport = {};
    },
  },
  actions: {
    createConsumer({ commit, dispatch }, context) {
      const { consumer, mediaElement, peerId, spaceId } = context;
      const consumerId = consumer.id;
      commit("CREATE_CONSUMER", { consumer });
      dispatch(
        "room/spaces/addMediaToPeer",
        { mediaElement, spaceId, peerId },
        { root: true }
      );
      dispatch(
        "socket/actionsEmit/consumerCreated",
        { consumerId },
        { root: true }
      );
    },
    removeConsumer({ commit }, context) {
      commit("REMOVE_CONSUMER", context);
    },
    closeConsumer({ commit }, context) {
      const { consumerId } = context;
      commit("REMOVE_CONSUMER", { consumerId });
    },
    pauseConsumer({ commit }, context) {
      commit("PAUSE_CONSUMER", context);
    },
    resumeConsumer({ commit }, context) {
      commit("RESUME_CONSUMER", context);
    },
    addConsumerTransport({ commit }, context) {
      commit("ADD_CONSUMER_TRANSPORT", context);
    },
    clearState({ commit }) {
      commit("CLEAR_STATE");
    },
  },
  getters: {
    getConsumer: (state) => (consumerId) => {
      const consumer = state.consumers.get(consumerId);
      if (!consumer) {
        console.log(constant.CONSUMER_NOT_FOUND_ERROR);
        return;
      }
      return state.consumers.get(consumerId);
    },
    getAllAudioConsumers: (state) => {
      return [
        ...Array.from(state.consumers.values()).filter(
          (consumer) => consumer.kind === constant.AUDIO
        ),
      ];
    },
  },
};
