<template>
  <div class="link-back flex items-center">
    <q-icon size="xs" name="arrow_back" />
    <base-link :is-external="false" to="/">Back</base-link>
  </div>
</template>

<script>
import BaseLink from "@/components/BaseLink";
export default {
  name: "BaseLinkBack",
  components: { BaseLink },
};
</script>

<style lang="scss" scoped>
.link-back {
  color: $white;
  gap: 1rem;
}
</style>
