// Variables associated with socket event in the server side
export const CREATE_ROOM = "createRoom";
export const JOIN = "join";
export const GET_ROUTER_RTP_CAPABILITIES = "getRouterRtpCapabilities";
export const CREATE_WEB_RTC_TRANSPORT = "createWebRtcTransport";
export const CONNECT_TRANSPORT = "connectTransport";
export const PRODUCE = "produce";
export const CONSUME = "consume";
export const RESUME = "resume";
export const GET_PRODUCERS = "getProducers";
export const SEND_CHAT_MESSAGE = "sendChatMessage";
export const EMOTION = "emotion";
export const CLOSE_CONSUMER = "consumerClosed";
export const CONSUMER_CREATED = "consumerCreated";
export const CLOSE_PRODUCER = "producerClosed";
export const CHANGE_SPACE = "changeSpace";
export const PAUSE_PRODUCER = "producerPaused";
export const RESUME_PRODUCER = "producerResumed";

//Router
export const LANDING = "landing";
export const MEETING = "meeting";
export const ROOM = "room";

//Updating props
export const UPDATE_MODEL_VALUE = "update:modelValue";

// Errors
export const CREATE_ROOM_ERROR = "Create room error: ";
export const UNSUPPORTED_ERROR = "UnsupportedError";
export const UNSUPPORTED_BROWSER_ERROR = "Browser not supported";
export const PRODUCER_CREATE_ERROR = "Cannot produce producer: ";
export const PRODUCER_EXISTS_ERROR = "Producer already exists for this type ";
export const PRODUCER_NOT_FOUND_ERROR = "Producer not found for this type ";
export const CONSUMER_NOT_FOUND_ERROR = "Consumer not found";

// Hints
export const AUDIO_OUTPUT_HINT =
  "To change the playback device, use the settings of your browser";

// Devices
export const AUDIO_INPUT = "audioInput";
export const AUDIO_OUTPUT = "audioOutput";
export const VIDEO_INPUT = "videoInput";

// Actions
export const CONNECT = "connect";
export const CONNECTION_STATE_CHANGE = "connectionstatechange";
export const TRACK_ENDED = "trackended";
export const TRANSPORT_CLOSE = "transportclose";
export const TOGGLE_ANIMATION = "toggle-animation";

//Message
export const MESSAGE_MAP_SOCKET = "Connecting with server";
export const MESSAGE_CREATE_ROOM = "Creating room";
export const MESSAGE_JOIN_ROOM = "Joining room";
export const MESSAGE_INIT_TRANSPORTS = "Establishing connection";
export const MESSAGE_LOAD_MODEL = "Preparing emotion recognition model";
export const MESSAGE_TRANSPORT_STATE = "Transport state changed";
export const MESSAGE_TRANSPORT_CLOSED = "Transport closed";
export const MESSAGE_TRACK_ENDED = "Track ended";

// Stream
export const VIDEO = "video";
export const AUDIO = "audio";
export const SCREEN_SHARE = "screen";

// Animations
export const EMOTIONS_TABLE_MAX_LENGTH = 100;

// Tables
export const DEFAULT_TABLE_NAMES = ["1", "2", "3", "4"];

// Enums
export const spacesEnum = Object.freeze({
  SCENE: "scene",
  LOBBY: "lobby",
});

// Theme
export const LIGHT_THEME = "light";
export const DARK_THEME = "dark";

// Colors
export const EMOTIONS_COLORS = {
  happy: "#ff9f0a",
  sad: "#17e2e2",
  surprised: "#0fa4e0",
  angry: "#ff1212",
  fearful: "#ad4cd2",
  disgusted: "#7af687",
};

export const AVATAR_COLORS = [
  "#0a84ff",
  "#ac8e68",
  "#5ac8f5",
  "#98989d",
  "#ff375f",
  "#bf5af2",
  "#ff453a",
  "#ffd60a",
  "#ff9f0a",
  "#32d74b",
  "#5e5ce6",
  "#66d4cf",
  "#019592",
  "#5600e8",
  "#005457",
  "#30009c",
];
