<template>
  <div
    :class="[
      `space-name__size--${size}`,
      'space-name',
      'full-height',
      'absolute-left',
    ]"
  >
    <span
      :class="[
        isSceneAndNoVideoOnDesktop(name) ? 'text-h2' : 'text-h5',
        'text-bold',
        'absolute-center',
      ]"
    >
      {{ name }}
    </span>
  </div>
</template>

<script>
import ViewportMixin from "@/mixins/ViewportMixin";
import SpacesMixin from "@/mixins/SpacesMixin";

export default {
  name: "BaseSpaceName",

  mixins: [ViewportMixin, SpacesMixin],

  props: {
    name: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: "xs",
      validator: (value) => {
        return ["xs", "sm"].includes(value);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.space-name {
  border-radius: $border-radius-md 0 0 $border-radius-md;
  background-color: $grey-5;
  color: $white;
  &__size {
    &--xs {
      width: $standard-size-xs;
    }
    &--sm {
      width: $standard-size-sm;
    }
  }
}
</style>
