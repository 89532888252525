import * as constant from "@/utils/consts";

export default {
  namespaced: true,
  state: {
    audioInput: {},
    audioOutput: {},
    videoInput: {},
  },
  mutations: {
    SET_MEDIA_DEVICE(state, { type, device }) {
      state[type] = device;
    },
  },
  actions: {
    async setMediaDevice(
      { dispatch, commit },
      { type, device, defaultDevice = false }
    ) {
      commit("SET_MEDIA_DEVICE", { type, device });

      if (type === constant.AUDIO_OUTPUT || defaultDevice) {
        return;
      }

      const mediaType =
        type === constant.AUDIO_INPUT ? constant.AUDIO : constant.VIDEO;
      await dispatch(
        "media/ownStreams/recreateStream",
        { type: mediaType },
        { root: true }
      );
    },
  },
};
