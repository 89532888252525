import mediaDevices from "@/store/modules/media/mediaDevices";
import ownStreams from "@/store/modules/media/ownStreams";

export default {
  namespaced: true,
  modules: {
    mediaDevices,
    ownStreams,
  },
  actions: {
    clearState({ dispatch }) {
      dispatch("ownStreams/clearState");
    },
  },
};
