import * as constant from "@/utils/consts";
import { mapActions, mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      device: (state) => state.mediasoupWrapper.device,
      consumerTransport: (state) =>
        state.mediasoupWrapper.mediasoupConsumer.consumerTransport,
    }),
  },

  methods: {
    ...mapActions({
      consumeTransportSocket: "socket/actionsRequest/consumeTransport",
      closeConsumerStore: "mediasoupWrapper/mediasoupConsumer/closeConsumer",
    }),
    async _createConsumerWithMedia(producerId, peerId, spaceId) {
      const transport = await this.consumeTransportSocket({
        rtpCapabilities: this.device.rtpCapabilities,
        consumerTransportId: this.consumerTransport.id,
        producerId,
      });
      if (!transport) {
        return;
      }
      const { id, kind, rtpParameters } = transport;
      const consumer = await this.consumerTransport.consume({
        id,
        kind,
        rtpParameters,
        producerId,
        codecOptions: {},
      });

      const srcObject = this._createConsumerStream(consumer);
      this._createConsumerEventListeners(consumer, peerId, spaceId);

      const mediaElement = {
        srcObject,
        id: consumer.id,
        kind: consumer.kind,
      };

      return { consumer, mediaElement };
    },
    _createConsumerStream(consumer) {
      const stream = new MediaStream();
      stream.addTrack(consumer.track);
      return stream;
    },
    _createConsumerEventListeners(consumer, peerId, spaceId) {
      const consumerId = consumer.id;
      const type = consumer.kind;
      const logInfo = `Type: ${type}, ConsumerId: ${consumerId}, PeerId: ${peerId}`;

      consumer.on(constant.TRANSPORT_CLOSE, () => {
        console.log(`${constant.MESSAGE_TRANSPORT_CLOSED}: ${logInfo}`);
        this.closeConsumerStore({ consumerId, peerId, spaceId });
      });

      consumer.on(constant.TRACK_ENDED, () => {
        console.log(`${constant.MESSAGE_TRACK_ENDED}: ${logInfo}`);
        this.closeConsumerStore({ consumerId, peerId, spaceId });
      });
    },
  },
};
