<template>
  <base-space
    :class="[
      'max-width',
      'q-ma-sm',
      {
        hidden: isLobby(spaceId),
        'q-mb-xl': peerOnTable,
      },
    ]"
    :space="space"
    :space-id="spaceId"
    @change-space="changeSpace"
  />
</template>

<script>
import BaseSpace from "@/components/BaseSpace";
import { CHANGE_SPACE } from "@/utils/consts";
import SpacesMixin from "@/mixins/SpacesMixin";

export default {
  name: "RoomSpacesTable",

  components: { BaseSpace },

  mixins: [SpacesMixin],

  props: {
    space: {
      type: Map,
      required: true,
    },
    spaceId: {
      type: String,
      required: true,
    },
  },

  emits: [CHANGE_SPACE],

  computed: {
    peerOnTable() {
      return this.isTable(this.spaceId) && this.space.size !== 0;
    },
  },

  methods: {
    changeSpace() {
      this.$emit(CHANGE_SPACE, this.spaceId);
    },
  },
};
</script>
